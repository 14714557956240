import React from "react";
import MissionVision from "./MissionVision";
import Team from "./Team";
import Header from "../Header";
import Footer from "../Footer";
import aboutStyles from "./AboutUs.module.css";
import Introduction from "./Introduction";
import FundInfo from "./FundInfo";

function AboutUs() {
  return (
    <div className={aboutStyles.container}>
      <Header />
      <div className={aboutStyles.separator} />
      <main className={aboutStyles.mainContent}>
        <h1 className={aboutStyles.title}>Bun venit la ctrl.fund!</h1>
        <h2 className={aboutStyles.subtitle}>Despre noi</h2>
        <Introduction />
        <MissionVision />
        <Team />
        {/* <FundInfo /> */}
      </main>
      <Footer />
    </div>
  );
}

export default AboutUs;
