import React from "react";

function Step3Content({ setSwitchStep }) {
  function handlePropNext() {
    setSwitchStep(4);
  }
  function handlePropPrev() {
    setSwitchStep(2);
  }
  return (
    <div className="add-company-form-step3">
      <h3>Pasul 3 - Financiare/Documente </h3>
      <form className="form-TVA">
        <label for="TVACheck">Platitor de TVA</label>
        <input name="TVACheck" className="TVACheck" type="checkbox" />
      </form>
      <form className="form-TVA">
        <label for="IMM-type">Tip de IMM:</label>
        <select name="IMM-type">
          <option value="micro">Micro</option>
          <option value="mica">Mica</option>
          <option value="medie">Medie</option>
          <option value="mare">Mare</option>
        </select>
      </form>
      <table>
        <tr>
          <th>Indicatori</th>
          <th>2023</th>
          <th>2022</th>
          <th>2021</th>
        </tr>
        <tr>
          <td className="first-table-line">Cifra de afaceri</td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
        </tr>
        <tr>
          <td className="first-table-line">Capitaluri proprii</td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
        </tr>
        <tr>
          <td className="first-table-line"> Profit net</td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
        </tr>
        <tr>
          <td className="first-table-line">Profit din exploatare</td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
        </tr>
        <tr>
          <td className="first-table-line">Active totale</td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
        </tr>
        <tr>
          <td className="first-table-line">Datorii totale</td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
        </tr>
        <tr>
          <td className="first-table-line">Nr. mediu de angajati</td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
        </tr>
        <tr>
          <td className="first-table-line">Bilant</td>
          <td>
            <input className="input-table" type="file" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="file" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="file" placeholder="123" />
          </td>
        </tr>
        <tr>
          <td className="first-table-line">Balanta</td>
          <td>
            <input className="input-table" type="file" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="file" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="file" placeholder="123" />
          </td>
        </tr>
        <tr>
          <td className="first-table-line">Rata de rentabilitate</td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
        </tr>
        <tr>
          <td className="first-table-line">Rata de solvabilitate</td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
        </tr>
        <tr>
          <td className="first-table-line">Productivitatea muncii</td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
        </tr>
        <tr>
          <td className="first-table-line">Nr. efectiv de angajati</td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
          <td>
            <input className="input-table" type="number" placeholder="123" />
          </td>
        </tr>
      </table>
      <form className="employee-number-form">
        <label for="employee-number">Nr. de angajati in prezent: </label>
        <input
          className="employee-number-input"
          name="employee-number"
          placeholder="123"
          type="number"
        />
      </form>
      <form className="form-mt">
        <label>Constatator extins: </label>
        <input type="file" />
      </form>
      <form className="form-mt">
        <label>Acte administrative(CUI): </label>
        <input type="file" />
      </form>
      <form className="form-mt">
        <label>
          Alte documente (prezentarea companiei, Plan de afaceri, Pitch, etc.):
        </label>
        <input type="file" />
      </form>
      <div className="step3-buttons">
        <button onClick={handlePropPrev} className="previous-step-button">
          Pasul anterior
        </button>
        <button onClick={handlePropNext} className="next-step-button">
          Urmatorul pas
        </button>
      </div>
    </div>
  );
}

export default Step3Content;
