export const error2message = (error) => {
    let errorMessage = "A apărut o eroare neașteptată.";
    console.log(error.response);
    console.log("WTF");
    if (error.response) {
        switch (error.response.status) {
            case 400:
                errorMessage = "Datele introduse sunt incorecte sau incomplete.";
                break;
            case 401:
                errorMessage = "Nu ești autorizat. Te rog să te autentifici.";
                break;
            case 403:
                errorMessage = "Acces interzis. Nu ai permisiunea necesară pentru această acțiune.";
                break;
            case 404:
                errorMessage = "Resursa solicitată nu a fost găsită.";
                break;
            case 422:
                errorMessage = "Datele nu pot fi procesate. Verifică informațiile introduse.";
                break;
            case 500:
                errorMessage = "Eroare internă a serverului. Încearcă din nou mai târziu.";
                break;
            default:
                errorMessage = error.message || errorMessage;
        }

        switch (error.response.data.detail){
            case "REGISTER_USER_ALREADY_EXISTS":
                errorMessage = "Un utilizator cu acest mail a fost deja inregistrat.";
                break;
            case "REGISTER_INVALID_PASSWORD":
                errorMessage = "Parola este invalida.";
                break;
            default:
                errorMessage = error.message || errorMessage;
        }
    } else {
        // If the error response doesn't contain a status code, use a generic error message
        errorMessage = error.message || "Eroare de rețea sau răspuns necunoscut de la server.";
    }
    return errorMessage;
}

export const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email) || "Adresa de email invalida";
  };

function parseDotDate(dateStr) {
    const [day, month, year] = dateStr.split('.').map(Number);
    return new Date(year, month - 1, day); // months are 0-indexed
}

function parseRomanianMonth(dateStr) {
    const monthMap = {
      "ianuarie": 0, "februarie": 1, "martie": 2, "aprilie": 3, "mai": 4, "iunie": 5,
      "iulie": 6, "august": 7, "septembrie": 8, "octombrie": 9, "noiembrie": 10, "decembrie": 11
    };
  
    const [months, year] = dateStr.split(' ');
  
    // Check if it's a month range like "noiembrie-decembrie"
    if (months.includes('-')) {
      const [startMonth, endMonth] = months.split('-').map(m => monthMap[m.toLowerCase()]);
    //   return {
    //     startDate: new Date(Number(year), startMonth, 1),
    //     endDate: new Date(Number(year), endMonth + 1, 0) // last day of the end month
    //   };
    return new Date(Number(year), endMonth + 1, 0);
    } else {
      // Single month case like "noiembrie 2015"
      const month = monthMap[months.toLowerCase()];
    //   return {
    //     startDate: new Date(Number(year), month, 1),
    //     endDate: new Date(Number(year), month + 1, 0) // last day of the same month
    //   };
    return new Date(Number(year), month + 1, 0)
    }
  }

export const parseDate = (dateStr) => {
    // Check if it's in "dd.mm.yyyy" format
    if (/\d{2}\.\d{2}\.\d{4}/.test(dateStr)) {
        // console.log("Date in format dd.mm.yyyy");
        // console.log(parseDotDate(dateStr));
      return parseDotDate(dateStr);
    }
    
    // Check if it's in "month-month year" or "month year" format
    if (/[a-zăâîșț]+(\-[a-zăâîșț]+)? \d{4}/i.test(dateStr)) {
        // console.log("Date in format month-month year");
        // console.log(parseRomanianMonth(dateStr));
      return parseRomanianMonth(dateStr);
    }
    
    // console.log("Unknown date format");
    // console.log(dateStr);
    return null;
  }