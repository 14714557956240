import React, { useEffect, useState } from "react";
import FinanceOption from "./FinanceOption";
import NavigationDrawer from "./NavigationDrawer2";
import Notifications from "./Notifications";
import Header from "../Feed/Header";
import styles from "./Feed2.module.css";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { loadUserFromStorage, initializeFilters } from "../../reducers/authReducer";
import ScrollToTopButton from "./ScrollToTopButton";
import axiosInstance from "../../services/axios";

// New LoadingCard component
const LoadingCard = () => (
  <div className={styles.loadingCard}>
    <div className={styles.loadingAnimation}></div>
  </div>
);

function Feed2() {
  const dispatch = useDispatch();
  const [searchResults, setSearchResultsInner] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const filters = useSelector((state) => state.auth.filters);

  const setSearchResults = (results) => {
    if (results.length < 10) {
      setHasMore(false);
      console.log("setSearchResults!");
      setSearchResultsInner(results);
    } else {
      setSearchResultsInner(results);
    }
  };

  useEffect(() => {
    dispatch(loadUserFromStorage());
    dispatch(initializeFilters());
    
    const handleScroll = () => {
      setShowScrollButton(window.pageYOffset > 600);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [dispatch]);

  const user = useSelector((state) => state.auth.user);
  const isLoading = useSelector((state) => state.auth.isLoading); // Check loading state

  const location = useLocation();

  useEffect(() => {
    if (location.state?.searchQuery) {
      const handleInitialSearch = async () => {
        try {
          setSearchResults([]);

          const response = await axiosInstance.get("/fundings/load/0", {
            params: {
              company_id: user?.currentCompanyId,
              text: location.state.searchQuery,
              ...location.state.filters,
            },
            withCredentials: true,
          });
          setSearchResults(response.data);
          window.history.replaceState({}, document.title);
        } catch (error) {
          console.error("Error during initial search:", error);
        }
      };

      handleInitialSearch();
    }
  }, [location.state]);

  if (isLoading) {
    return (<LoadingCard />);
  }

  if (!user) {
    return <Navigate to="/" replace />;
  }

  if (user && !user.is_verified) {
    return <Navigate to="/verify" replace />;
  }

  return (
    <div>
      <Header 
        setSearchResults={setSearchResults} 
        user={user}
        filters={filters}
        passedSearchQuery={location.state?.searchQuery}
      />
      <div className={styles.centered}>
        <div className={styles.app}>
          <NavigationDrawer className={styles.leftContainer} />
          <FinanceOption 
            searchResults={searchResults} 
            setSearchResults={setSearchResults} 
            filters={filters}
            className={styles.centerContainer}
            hasMore={hasMore}
            setHasMore={setHasMore}
          />
          <div className={styles.rightContainer}>
          </div>
        </div>
      </div>
      {showScrollButton && <ScrollToTopButton />}
    </div>
  );
}

export default Feed2;
