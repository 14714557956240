import React from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import { AiOutlineHeart, AiOutlineComment } from "react-icons/ai";
import eu_icon from "./assets/european_flag2.png";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";

function MiniCard({
  title,
  description,
  image,
  type,
  amount,
  likes,
  comments,
}) {
  let boxBg = useColorModeValue("white !important", "#111c44 !important");
  let secondaryBg = useColorModeValue("gray.50", "whiteAlpha.100");
  let mainText = useColorModeValue("gray.800", "white");
  let iconBox = useColorModeValue("gray.100", "whiteAlpha.200");

  return (
    <Flex
      borderRadius="15px"
      bg={boxBg}
      h="230px"
      w={{ base: "150px", md: "220px" }}
      direction="column"
      overflow="hidden"
      margin="5px"
    >
      <Image src={image} w="100%" h="70px" objectFit="cover" />
      <Box p="10px" flex="1">
        {title && (
          <Text
            fontWeight="300"
            color={mainText}
            w="100%"
            fontSize="s"
            mb="6px"
          >
            {title}
          </Text>
        )}
        <Text fontWeight="100" color="gray.500" w="100%" fontSize="sm">
          Tip: {type}
        </Text>
        <Text fontSize="xs" color={mainText} lineHeight="18px" mb="8px">
          {description}
        </Text>
      </Box>
      <Flex
        bg={secondaryBg}
        w="100%"
        p="2px 10px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex>
          <Flex align="center" me="15px" _hover={{ bg: iconBox }}>
            <Icon
              as={AiOutlineHeart}
              w="20px"
              h="20px"
              me="4px"
              color="gray.500"
            />
            <Text color={mainText} fontSize="xs" fontWeight="500">
              {5}
            </Text>
          </Flex>
          <Flex align="center" _hover={{ bg: iconBox }}>
            <Icon
              as={AiOutlineComment}
              w="20px"
              h="20px"
              me="4px"
              color="gray.500"
            />
            <Text color={mainText} fontSize="xs" fontWeight="500">
              {7}
            </Text>
          </Flex>
        </Flex>
        <Button
          w="80px"
          h="32px"
          align="center"
          justify="center"
          borderRadius="5px"
          bg={iconBox}
          fontSize="xs"
        >
          Mai multe
        </Button>
      </Flex>
    </Flex>
  );
}

export default MiniCard;
