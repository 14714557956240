import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { loginUser, setUser } from "../../reducers/authReducer";
import { useForm } from "react-hook-form";
import NavBar from "./NavBar"; // Adjust this import path as needed
import axiosInstance from "../../services/axios";
import {
  FormControl,
  FormErrorMessage,
  Input,
  useColorModeValue,
  useToast,
  Button,
} from "@chakra-ui/react";
import "./Landing.css"; // Make sure this path is correct
import styles from "../Auth/auth.module.css"; // Importing the custom CSS module
import { useGoogleLogin } from "@react-oauth/google";
import { useLinkedIn } from "react-linkedin-login-oauth2";

import { onSubmitLogin } from "../../utils/auth";

function Coming() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log(codeResponse);
      axiosInstance
        .post("/auth/google/authorize", {
          code: codeResponse.code,
        })
        .then((res) => {
          console.log(res);
          localStorage.setItem("accessToken", res.data.access);
          localStorage.setItem("refreshToken", res.data.refresh);
          dispatch(setUser(res.data.user));
          if (res.data.user.is_verified === true) {
            console.log("User is verified");
            navigate("/feed");
          } else {
            console.log("User is not verified");
            navigate("/verify");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    flow: "auth-code",
  });

  const { linkedInLogin } = useLinkedIn({
    clientId: "77f7629y04xc1a",
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      console.log(code);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    console.log("Getting token");
    const token = localStorage.getItem("accessToken");
    console.log(token);
    if (token && !user) {
      console.log("Fetching user");
      axiosInstance
        .get("/users/me", { withCredentials: true })
        .then((res) => {
          dispatch(setUser(res.data));
          if (res.data.is_verified === true) {
            console.log("User is verified");
            navigate("/feed");
          } else {
            console.log("User is not verified");
            navigate("/verify");
          }
        })
        .catch((err) => {
          console.log("Could not fetch user");
          console.log(err);
        });
    }
  }, [user, isAuthenticated, dispatch, navigate]); // dependencies array

  // const onSubmit = async (values) => {
  //   try {
  //     await dispatch(loginUser(values));
  //     toast({
  //       title: "Signed in successfully.",
  //       status: "success",
  //       isClosable: true,
  //       duration: 2500,
  //     });
  //     console.log("Is Authenticated: ", isAuthenticated);
  //     navigate("/feed", { replace: true });
  //   } catch (error) {
  //     toast({
  //       title: error,
  //       status: "error",
  //       isCloseable: true,
  //       duration: 2500,
  //     });
  //   }
  // };

  const onSubmit = (values) => {
    onSubmitLogin(dispatch, navigate, toast, values);
  };

  return (
    <div className="container">
      {user && !user.is_verified && <Navigate to="/verify" />}
      {user && user.is_verified && <Navigate to="/feed" />}
      <NavBar />

      <section className="section">
        <div className="title-container">
          <h1 className="title-top">Welcome to</h1>
          <img
            className="finaccess-logo"
            src="/images/FinaccesWord.png"
            alt=""
          />
        </div>
        <div className="container-mid">
          <div className="form">
            <form
              className={styles.registerForm}
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormControl isInvalid={errors.email}>
                <Input
                  className={styles.registerInput}
                  placeholder="Email"
                  background={useColorModeValue("gray.300", "gray.600")}
                  type="email"
                  size="lg"
                  mt={6}
                  {...register("email", {
                    required: "This is required field",
                  })}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.password}>
                <Input
                  className={styles.registerInput}
                  placeholder="Password (5+ characters)"
                  background={useColorModeValue("gray.300", "gray.600")}
                  type="password"
                  size="lg"
                  mt={6}
                  {...register("password", {
                    required: "This is required field",
                    minLength: {
                      value: 5,
                      message: "Password must be at least 5 characters long",
                    },
                    maxLength: {
                      value: 24,
                      message: "Password must be at most 24 characters",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>
              <div className={styles.forgotPasswordContainer}>
                <a href="/forgot-password">Forgot password?</a>
              </div>
              <Button
                isLoading={isSubmitting}
                loadingText="Signing in..."
                className={styles.registerButton}
                width="100%"
                type="submit"
              >
                Sign in
              </Button>
            </form>
            <Button
              isLoading={isSubmitting}
              loadingText="Signing in..."
              className={styles.alternateButton}
              width="100%"
              onClick={() => navigate("/signup", { replace: true })}
            >
              not on finaccess yet? <span> Join now </span>
            </Button>
            <div className={styles.or_separator}>or</div>
            <p className={styles.agreement}>
              {" "}
              By clicking Continue, you agree to the Finaccess{" "}
              <a href="/user-agreement">User Agreement</a>.
            </p>
            <Button
              isLoading={isSubmitting}
              loadingText="Signing in..."
              className={styles.smallAlternateButton}
              width="100%"
              onClick={googleLogin}
            >
              <img
                className={styles.continue_with_logo}
                src="/images/google.svg"
                alt="logo"
              ></img>
              Continue with Google
            </Button>
            <Button
              isLoading={isSubmitting}
              loadingText="Signing in..."
              className={styles.smallAlternateButton}
              width="100%"
              onClick={linkedInLogin}
            >
              <img
                className={styles.continue_with_logo}
                src="/images/linkedin.svg"
                alt="logo"
              ></img>
              Continue with LinkedIn
            </Button>
          </div>
          <img className="main-image" src="/images/login-hero.svg" alt="" />
        </div>
      </section>
    </div>
  );
}

export default Coming;
