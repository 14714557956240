import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import styles from './CookieBanner.module.css';

const CookieBanner = () => {
  const [visible, setVisible] = useState(true);

  const bannerStyle = {
    background: "#f3f2ef",
    color: "#333",
    fontSize: "14px",
    padding: "20px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    animation: "fadeIn 1s ease-in-out",
  };

  const buttonStyle = {
    background: "#749db4",
    color: "#fff",
    fontSize: "14px",
    borderRadius: "4px",
    padding: "10px 20px",
    border: "none",
    margin: "5px",
    transition: "background 0.3s",
    cursor: "pointer",
  };

  const declineButtonStyle = {
    background: "#e0e0e0",
    color: "#333",
    fontSize: "14px",
    borderRadius: "4px",
    padding: "10px 20px",
    border: "none",
    margin: "5px",
    transition: "background 0.3s",
    cursor: "pointer",
  };

  const contentStyle = {
    textAlign: "left",
  };

  const linkStyle = {
    textDecoration: "underline",
    color: "#0073b1",
  };

  const smallerTextStyle = {
    fontSize: "12px",
    color: "#666",
  };

  return (
    visible && (
      <CookieConsent
        sameSite="none"
        cookieSecurity={false}
        location="bottom"
        // flipButtons={true}
        // hideOnAccept={false}
        buttonText="Accept"
        declineButtonText="Respinge"
        enableDeclineButton
        cookieName="consentCookie"
        style={bannerStyle}
        buttonStyle={buttonStyle}
        declineButtonStyle={declineButtonStyle}
        contentStyle={contentStyle}
        containerClasses={styles.cookieBanner}
        buttonClasses={styles.button}
        declineButtonClasses={styles.declineButton}
        expires={150}
        overlayStyle={{ animation: "fadeIn 1s ease-in-out" }}
      >
        Ctrl.fund și terți folosesc cookie-uri esențiale și neesențiale pentru a
        furniza, securiza, analiza și îmbunătăți Serviciile noastre. Aflați mai multe în <a href="#" style={linkStyle}>Politica noastră privind cookie-urile</a>.
        <br />
        {/* <br /> */}
        Selectați Accept pentru a consimți sau Respinge pentru a refuza cookie-urile neesențiale pentru
        această utilizare. Vă puteți actualiza opțiunile în orice moment în <a href="#" style={linkStyle}>setările dvs.</a>.{" "}
      </CookieConsent>
    )
  );
};

export default CookieBanner;