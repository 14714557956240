import React from 'react';
import styles from './HowItWorks.module.css';

const HowItWorks = React.forwardRef((props, ref) => {
  return (
    <section ref={ref} className={styles.howItWorks}>
      <h2 className={styles.title}>Cum functioneaza ctrl.fund?</h2>
      <div className={styles.steps}>
        <div className={styles.step}>
          <div className={styles.circle}>1</div>
          <div className={styles.stepDetails}>
            <h3 className={styles.stepTitle}>Creeaza-ti profilul</h3>
            <p className={styles.stepDescription}>Configureaza profilul afacerii tale</p>
          </div>
        </div>
        <div className={styles.line} />
        <div className={styles.step}>
          <div className={styles.circle}>2</div>
          <div className={styles.stepDetails}>
            <h3 className={styles.stepTitle}>Alege finantarea</h3>
            <p className={styles.stepDescription}>Gaseste cea mai potrivita finantare pentru afacerea ta</p>
          </div>
        </div>
        <div className={styles.line} />
        <div className={styles.step}>
          <div className={styles.circle}>3</div>
          <div className={styles.stepDetails}>
            <h3 className={styles.stepTitle}>Exploreaza</h3>
            <p className={styles.stepDescription}>Descopera diversitatea oportunitatilor de finantare</p>
          </div>
        </div>
      </div>
    </section>
  );
});

export default HowItWorks;
