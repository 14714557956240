import React from "react";
import { useSelector } from "react-redux";
import leftStyle from "./Left.module.css";

// Assuming you're using react-icons for demonstration
import { FaBuilding, FaRegBookmark } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCompaniesSuccess, setCurrentCompany } from "../../reducers/authReducer";
import axiosInstance from "../../services/axios";


function Left() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const companies = useSelector((state) => state.auth.companies);
  const current_company_id = useSelector((state) => state.auth.current_company_id);
  let photoUrl = user && user.photoURL ? user.photoURL : "/images/user.svg";

  useEffect(() => {
    if (!companies) {
      axiosInstance.get("/companies/getAll", { withCredentials: true })
        .then((res) => {
          dispatch(setCompaniesSuccess(res.data));
          if (current_company_id == null && companies.length > 0) {
            dispatch(setCurrentCompany(companies[0]._id));
          }
        })
        .catch((error) => {
          console.error("Could not fetch companies", error);
        });
    }
  }, [companies, dispatch]);

  return (
    <div className={leftStyle.container}>
      <div className={leftStyle.artCard}>
        <div className={leftStyle.userInfo}>
          <div className={leftStyle.cardBackground} />
          <Link to="/user/profile">
            <div
              className={leftStyle.photo}
              style={{ backgroundImage: `url(${photoUrl})` }}
            />
            <div className={leftStyle.link}>
              Welcome, {user ? user.name : "there"}!
            </div>
          </Link>
          <a href="/profile">
            <div className={leftStyle.addPhotoText}>Go to profile</div>
          </a>
        </div>
        <div className={leftStyle.divider} />
        {/* User's Companies List */}
        <div className={leftStyle.section}>
          <div className={leftStyle.sectionHeader}>My Companies</div>
          <div className={leftStyle.companiesList}>
            {companies && companies.length > 0 ? (
              companies.map((company) => (
                <div
                  key={company._id}
                  className={`${leftStyle.navItem} ${current_company_id === company._id ? leftStyle.selected : ""}`}
                  onClick={() => dispatch(setCurrentCompany(company._id))}
                >
                  <div className={leftStyle.companyLink}>
                    {company.company_name}
                  </div>
                </div>
              ))
            ) : (
              <Link to="/onboard" className={leftStyle.addCompanyMessage}>
                Add a company
              </Link>
            )}
          </div>
        </div>
        {/* Saved Fundings */}
        <div className={leftStyle.section}>
          <div className={leftStyle.sectionHeader}>Saved Fundings</div>
          <Link to="/savedFundings" className={leftStyle.navItem}>
            View Saved Fundings
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Left;
