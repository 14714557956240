import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import FinanceCard from "./FinanceCard";
import axiosInstance from "../../services/axios";
import styles from "./Feed2.module.css"

function FundingScroll({searchResults,
  setSearchResults,
  filters,
  searchText,
  hasMore,
  setHasMore
}) {
  // const [hasMore, setHasMore] = useState(true);

  const fetchMoreData = () => {
    if (!hasMore) {
      console.log("No more data");
      return;
    }
    console.log("Fetching more data");
    const url = `/fundings/load/${searchResults.length}`;
    
    const company_id = localStorage.getItem("current_company_id");

    const params = {
      company_id: company_id,
      search_text: searchText,
      ...filters,
      offset: searchResults.length
    };

    axiosInstance
      .get(url, { params, withCredentials: true })
      .then((response) => response.data)
      .then((newItems) => {
        if (newItems.length < 10) {
          setHasMore(false);
          return;
        }
        setSearchResults(prevResults => [...prevResults, ...newItems]);
      })
      .catch((error) => console.error("Error:", error));
  };

  // Update hasMore when searchResults changes
  // useEffect(() => {
  //   setHasMore(searchResults.length > 0);
  // }, [searchResults]);

  // Fetch initial data on mount or when filters/searchText change
  // useEffect(() => {
  //   console.log("Fetching initial data in scroll");
  //   setSearchResults([]);
  //   fetchMoreData();
  // }, [filters, searchText]);

  useEffect(() => {
    fetchMoreData();
  });

  const LoadingCard = () => (
    <div className={styles.loadingCard}>
      <div className={styles.loadingAnimation}></div>
    </div>
  );

  const NoResultsMessage = () => (
    <div className={styles.endCard}>
      <p className={styles.noResultsMessage}>
        <b>Nu s-au găsit finanțări relevante.</b>
      </p>
    </div>
  );

  const EndListMessage = () => (
    <div className={styles.endCard}>
      <p className={styles.noResultsMessage}>
        <b>Asta e tot!</b>
      </p>
    </div>
  );

  return (
    <div className={styles.fundingScrollContainer}>
      {searchResults.length === 0 && !hasMore ? (
        <NoResultsMessage />
      ) : (
        <InfiniteScroll
          dataLength={searchResults.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<LoadingCard />}
          // endMessage={
          //   <p className={styles.endMessage}>
          //     <b>Ai ajuns la sfârșitul listei!</b>
          //   </p>
          // }
          endMessage={<EndListMessage />}
        >
          {searchResults.map((item, index) => (
            <FinanceCard key={index} funding={item} />
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
}

export default FundingScroll;
