import React from "react";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  useToast,
  Box,
  Icon,
} from "@chakra-ui/react";
import { FaGoogle, FaLinkedin } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser, getUser } from "../../reducers/authReducer";
import { Link } from "react-router-dom";
import { error2message, validateEmail } from "../../utils/general";
import styles from "./auth.module.css"; // Importing the custom CSS module
import axiosInstance from "../../services/axios";

function Signin2() {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();

  const handleOAuth = (provider) => {
    axiosInstance
      .get(`/auth/${provider}/authorize`) //${process.env.REACT_APP_API_BASE_URL}
      .then((res) => {
        console.log(res.data);
        window.open(res.data.authorization_url, "_self");
      });
  };

  const onSubmit = async (values) => {
    try {
      await dispatch(loginUser(values));
      const verified = await dispatch(getUser());
      toast({
        title: "Te-ai autentificat cu succes!",
        status: "success",
        isClosable: true,
        duration: 2500,
      });
      if (verified) {
        navigate("/feed", { replace: true });
      } else {
        navigate("/verify", { replace: true });
      }
    } catch (error) {
      let errorTitle = error2message(error);
      toast({
        title: errorTitle,
        status: "error",
        isCloseable: true,
        duration: 2500,
      });
    }
  };

  return (
    <Flex height="100vh" alignItems="center" justifyContent="center">
      <Flex
        className={styles.registerContainer}
        direction="column"
        alignItems="center"
        background="#eee"
        p={12}
        rounded={6}
      >
        <Heading className={styles.registerHeading} mb={6}>
          Intra in cont
        </Heading>
        <p className={styles.subheader}>
          Stai la curent cu oportunitatile tale financiare
        </p>
        <form className={styles.registerForm} onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors.email}>
            <Input
              className={styles.registerInput}
              placeholder="Email"
              background="#fff"
              type="email"
              size="lg"
              mt={6}
              {...register("email", {
                required: "Acest camp este obligatoriu",
                validate: validateEmail,
              })}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.password}>
            <Input
              className={styles.registerInput}
              placeholder="Parola (8+ caractere)"
              background="#fff"
              type="password"
              size="lg"
              mt={6}
              {...register("password", {
                required: "Acest camp este obligatoriu",
                minLength: {
                  value: 6,
                  message: "Parola trebuie sa aiba cel putin 8 caractere",
                },
                maxLength: {
                  value: 24,
                  message: "Parola trebuie sa aiba cel mult 24 de caractere",
                },
              })}
            />
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>
          <div className={styles.forgotPasswordContainer}>
            <Link to="/forgot-password">Ai uitat parola?</Link>
          </div>
          <Button
            isLoading={isSubmitting}
            loadingText="Signing in..."
            className={styles.registerButton}
            width="100%"
            type="submit"
          >
            Sign in
          </Button>
          <Box mt={6}>
            <Button
              leftIcon={<Icon as={FaGoogle} color="red.500" />}
              onClick={() => handleOAuth("google")}
              backgroundColor="#F5F5F5" // Light gray background
              colorScheme="red" // Subtle hint of Google red on hover
              color="gray.600" // Dark gray text for better contrast
              _hover={{ bg: "#EDEDED", color: "red.500" }} // Lighter gray on hover with red text
              width="100%"
              mb={2}
            >
              Sign up with Google
            </Button>
            <Button
              leftIcon={<Icon as={FaLinkedin} color="blue.500" />}
              onClick={() => handleOAuth("linkedin")}
              backgroundColor="#F5F5F5" // Light gray background
              colorScheme="blue" // Subtle hint of LinkedIn blue on hover
              color="gray.600" // Dark gray text for better contrast
              _hover={{ bg: "#EDEDED", color: "blue.500" }} // Lighter gray on hover with blue text
              width="100%"
            >
              Sign up with LinkedIn
            </Button>
          </Box>
        </form>
        {/* <p className="or-paragraph">
          .............................<span>or</span>
          ............................
        </p> */}
        {/* <Button
          onClick={() => navigate("/", { replace: true })}
          className={styles.alternateButton}
          width="100%"
          mt={6}
        >
          Login Instead
        </Button> */}
        <div className={styles.joinNowContainer}>
          <p>
            Esti nou pe ctrl.fund? <Link to="/signup">Inregistreaza-te!</Link>{" "}
          </p>
        </div>
      </Flex>
    </Flex>
  );
}

export default Signin2;
