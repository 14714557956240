import React from "react";

function Step1Content({ handleAutocomplete, data, updateData, setSwitchStep }) {
  return (
    <div className="add-company-form">
      <div className="first-step">
        <h3>Pasul 1 - Incepem aici</h3>
        <br />
        <p>
          Te rugam sa introduci mai jos <br /> CUI-ul
        </p>
        <form className="form-CUI">
          <input
            placeholder="RO42171928"
            className="CUI-Input"
            type="text"
            value={data.cui}
            onChange={(e) => updateData({ ...data, cui: e.target.value })}
          />
          <button
            type="button"
            className="CUI-button"
            onClick={() => {
              console.log(data.cui);
              handleAutocomplete(data.cui);
            }}
          >
            precompleteaza
          </button>
        </form>
        <form>
          <label className="label-font">Denumire Companie</label>
          <input
            placeholder="DENUMIRE COMPANIE SRL"
            className="Company-name input-step1"
            type="text"
            value={data.companyName}
            onChange={(e) =>
              updateData({ ...data, companyName: e.target.value })
            }
          />
        </form>
        <br />
        <div className="date-register">
          <form className="form-flex">
            <label className="label-date label-font">Data infiintarii</label>
            <input
              className="input-date"
              type="date"
              value={data.registrationDate}
              onChange={(e) =>
                updateData({ ...data, registrationDate: e.target.value })
              }
            />
          </form>
          <form className="form-flex">
            <label className="label-number label-font">
              Nr. de inmatriculare
            </label>
            <input
              placeholder="J22/242/2020"
              className="input-number"
              type="text"
              value={data.registrationNumber}
              onChange={(e) =>
                updateData({ ...data, registrationNumber: e.target.value })
              }
            />
          </form>
        </div>
        <br />
        <form>
          <label className="label-font">Adresa sediul social</label>
          <input
            placeholder="București, Str. FINACCESS 3, et.1"
            className="Company-name input-step1"
            type="text"
            value={data.companyAddress}
            onChange={(e) =>
              updateData({ ...data, companyAddress: e.target.value })
            }
          />
        </form>
        <button onClick={() => setSwitchStep(2)} className="next-step-button">
          Urmatorul pas
        </button>
      </div>
      <div className="instructions">
        <h3>Instructiuni de completare:</h3>
        <br />
        <div className="display-flex">
          <img
            className="circle-right"
            src="/images/angle-circle-right.png"
            alt=""
          />
          <p className="instruction-p1">
            Introdu CUI-ul companiei tale (cu RO sau fără, nu contează) și apasă
            butonul precompletează. Datele vor fi populate automat prin
            partenerul nostru partener.ro
          </p>
        </div>
        <br />
        <div className="display-flex">
          <img
            className="circle-right"
            src="/images/angle-circle-right.png"
            alt=""
          />
          <p>
            Preferi să introduci manual sau ai detectat o eroare? Fă clic pe
            câmpurile corespunzătoare pentru a adăuga sau corecta informațiile
            necesare. Asigură-te că datele sunt precise pentru o experiență
            completă.
          </p>
        </div>
        <br />
        <div className="display-flex">
          <img
            className="circle-right"
            src="/images/angle-circle-right.png"
            alt=""
          />
          <p>
            Nu deții o companie încă ? Nici o problemă. Sigur te-ai gândit la
            denumirea viitoarei tale companii. Glisează butonul și completează o
            denumire pentru a explora oportunitățile de finanțare.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Step1Content;
