import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setFilters } from "../../reducers/authReducer";
import { FaFilter, FaSort } from "react-icons/fa";
import "./SearchDropdown.css";

const regions = {
  "Nord-Vest": ["Bihor", "Bistrița-Năsăud", "Cluj", "Maramureș", "Satu Mare", "Sălaj"],
  "Nord-Est": ["Bacău", "Botoșani", "Iași", "Neamț", "Suceava", "Vaslui"],
  "Centru": ["Alba", "Brașov", "Covasna", "Harghita", "Mureș", "Sibiu"],
  "Sud-Est": ["Brăila", "Buzău", "Constanța", "Galați", "Tulcea", "Vrancea"],
  "Sud-Muntenia": ["Argeș", "Călărași", "Dâmbovița", "Giurgiu", "Ialomița", "Prahova", "Teleorman"],
  "București-Ilfov": ["București", "Ilfov"],
  "Sud-Vest Oltenia": ["Dolj", "Gorj", "Mehedinți", "Olt", "Vâlcea"],
  "Vest": ["Arad", "Caraș-Severin", "Hunedoara", "Timiș"]
};

const countActiveFilters = (filters) => {
  let count = 0;
  if (filters.status !== "all") count++;
  if (filters.region !== "all") count++;
  if (filters.eligibility !== "all") count++;
  if (filters.startDate) count++;
  if (filters.endDate) count++;
  if (filters.sortBy !== "relevance") count++;
  return count;
};

const SearchDropdown = ({ onSearch }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.auth.filters);
  
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    dispatch(setFilters({ [id]: value }));
  };

  const handleSortChange = (e) => {
    const { value } = e.target;
    dispatch(setFilters({ sortBy: value }));
  };

  const handleSortOrderChange = (e) => {
    const { value } = e.target;
    dispatch(setFilters({ sortOrder: value }));
  };

  const handleSubmit = () => {
    onSearch(filters);
  };

  const handleResetFilters = () => {
    dispatch(setFilters({
      status: "all",
      region: "all",
      startDate: null,
      endDate: null,
      sortBy: "relevance",
      sortOrder: "desc",
      eligibility: "all"
    }));
  };

  return (
    <div className="search-dropdown">
      <div className="filtering-group">
        <h4>
          <FaFilter />
          Filtrare
        </h4>
        <div className="filter-group">
          <label htmlFor="status">Status</label>
          <select
            id="status"
            value={filters.status}
            onChange={handleInputChange}
          >
            <option value="all">Toate</option>
            <option value="active">Activ</option>
            <option value="inactive">Inactiv</option>
            <option value="pending">Urmează</option>
          </select>
        </div>
        <div className="filter-group">
          <label htmlFor="eligibility">Scor</label>
          <select
            id="eligibility"
            value={filters.eligibility}
            onChange={handleInputChange}
          >
            <option value="all">Toate</option>
            <option value="eligible">Potrivit</option>
            <option value="posible">Posibil</option>
            <option value="ineligible">Nepotrivit</option>
          </select>
        </div>
        <div className="filter-group">
          <label htmlFor="region">Regiune</label>
          <select
            id="region"
            value={filters.region}
            onChange={handleInputChange}
          >
            <option value="all">Toate</option>
            {Object.entries(regions).map(([regionName, counties]) => (
              <optgroup key={regionName} label={regionName}>
                {counties.map((county) => (
                  <option key={county} value={county}>
                    {county}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
        </div>
        {/* <div className="filter-group">
          <label htmlFor="keywords">Cuvinte Cheie</label>
          <input
            type="text"
            id="keywords"
            placeholder="Introducei cuvinte cheie"
            value={filters.keywords}
            onChange={handleInputChange}
          />
        </div> */}
        <div className="filter-group">
          <label htmlFor="startDate">Data de incepere</label>
          <input
            type="date"
            id="startDate"
            value={filters.startDate}
            onChange={handleInputChange}
          />
        </div>
        <div className="filter-group">
          <label htmlFor="endDate">Data de inchidere</label>
          <input
            type="date"
            id="endDate"
            value={filters.endDate}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="sorting-group">
        <h4>
          <FaSort /> Sortare
        </h4>
        <div className="sorting-options">
          <label>
            <input
              type="radio"
              name="sort"
              value="score"
              checked={filters.sortBy === "score"}
              onChange={handleSortChange}
            />
            Scor
          </label>
          <label>
            <input
              type="radio"
              name="sort"
              value="deadline"
              checked={filters.sortBy === "deadline"}
              onChange={handleSortChange}
            />
            Data de inchidere
          </label>
          <label>
            <input
              type="radio"
              name="sort"
              value="start"
              checked={filters.sortBy === "start"}
              onChange={handleSortChange}
            />
            Data de incepere
          </label>
        </div>
        <div className="sort-order-options">
          <label>
            <input
              type="radio"
              name="sortOrder"
              value="asc"
              checked={filters.sortOrder === "asc"}
              onChange={handleSortOrderChange}
            />
            Ascendent
          </label>
          <label>
            <input
              type="radio"
              name="sortOrder"
              value="desc"
              checked={filters.sortOrder === "desc"}
              onChange={handleSortOrderChange}
            />
            Descendent
          </label>
        </div>
      </div>

      <div className="search-actions">
        <button 
          className="reset-button" 
          onClick={handleResetFilters}
          style={{ 
            marginRight: '10px',
            opacity: countActiveFilters(filters) > 0 ? 1 : 0.5,
            cursor: countActiveFilters(filters) > 0 ? 'pointer' : 'default'
          }}
        >
          Resetează filtrele
        </button>
        <button className="search-button" onClick={handleSubmit}>
          Aplică
        </button>
      </div>
    </div>
  );
};

export default SearchDropdown;
