import React, { useState, useEffect } from "react";

function Step2Content({ data, updateData, setSwitchStep }) {

  return (
    <div className="add-company-form">
      {/* adaugare companie primul pas */}
      <div className="first-step">
        <h3>Pasul 2 - Configurare Profil </h3>
        <br />
        <p>Incarca logoul</p>
        <form className="form-CUI">
          <input className="CUI-Input" type="file" />
        </form>
        <form>
          <label className="label-font">Descrierea scurta a companiei</label>
          <textarea
            rows="4"
            cols="10"
            placeholder="Descriere companie "
            className="company-description "
            value={data.companyDescription}
            onChange={(e) => updateData({ ...data, companyDescription: e.target.value })}
          />
        </form>
        <br />
        <form>
          <label className="label-font">Domeniul de activitate</label>
          <input
            placeholder="Codul Caen"
            className="Company-name input-step1"
            type="text"
            value={data.authorizedCaenCodes}
            onChange={(e) => updateData({ ...data, authorizedCaenCodes: e.target.value })}
          />
        </form>
        <form>
          <label className="label-font">Punct de lucru</label>
          <input
            placeholder="Adresa"
            className="Company-name input-step1"
            type="text"
          />
        </form>
        {/* <form className="display-flex toggle-form">
              <p>Nu detin o companie</p>
              <label className="label-toggle">
                <input
                  className="input-toggle"
                  type="checkbox"
                  // defaultChecked={isToggled}
                  // onClick={callback}
                />
                <span className="span-toggle" />
                <strong>{/* {label} </strong>
              </label>
            </form> */}
        <div className="step-buttons">
          <button onClick={() => setSwitchStep(1)} className="previous-step-button">
            Pasul anterior
          </button>
          <button onClick={() => setSwitchStep(3)} className="next-step-button">
            Urmatorul pas
          </button>
        </div>
      </div>
      {/* instructiuni adaugare companie */}
      <div className="instructions">
        <p>
          Adăuga o scurta descriere a companiei (care este domeniul de
          activitate, principalele produse/servicii). De exemplu: Societatea X
          SRL este un producator de mobila din Valcea, care fabrica mese si
          scaune pt birou.
        </p>
      </div>
    </div>
  );
}

export default Step2Content;
