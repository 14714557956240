import React from 'react';
import missionStyles from './MissionVision.module.css';

const MissionVision = () => {
  return (
    <div className={missionStyles.container}>
      <h2 className={missionStyles.subtitle}>Viziune si misiune</h2>
      <div className={missionStyles.content}>
        <p>
          Viziunea noastră consta in oferirea unei solutii fintech, folosind tehnologia pentru a îmbunătăți incluziunea financiară a afacerilor
          într-un ecosistem complex. Misiunea noastră este să facem finanțarea mai accesibilă, simplificând procesele și oferind o platformă digitală
          pentru agregarea completă a produselor financiare.
        </p>
      </div>
    </div>
  );
};

export default MissionVision;
