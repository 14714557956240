import React from "react";
import teamStyles from "./Team.module.css";
import DionisPhoto from "../assets/rectangle-955475.svg";
import CosminPhoto from "../assets/rectangle-955475-2.svg";
import MihailPhoto from "../assets/rectangle-955475-3.svg";
import BogdanPhoto from "../assets/rectangle-955475-4.svg";
import LinkedinIcon from "../assets/image-21.svg";

const Team = () => {
  const teamMembers = [
    {
      name: "Dionis Schimbător",
      title: "CEO & Founder",
      imgSrc: DionisPhoto,
      linkedIn: "#",
    },
    {
      name: "Cosmin Moisii",
      title: "CTO & Founder",
      imgSrc: CosminPhoto,
      linkedIn: "#",
    },
    {
      name: "Mihail Mistret",
      title: "COO",
      imgSrc: MihailPhoto,
      linkedIn: "#",
    },
    {
      name: "Bogdan Hutuleac",
      title: "Data analyst",
      imgSrc: BogdanPhoto,
      linkedIn: "#",
    },
  ];

  return (
    <div className={teamStyles.container}>
      <h2 className={teamStyles.subtitle}>Echipa</h2>
      <div className={teamStyles.team}>
        {teamMembers.map((member, index) => (
          <div key={index} className={teamStyles.member}>
            <img
              src={member.imgSrc}
              alt={`${member.name}`}
              className={teamStyles.image}
            />
            <div className={teamStyles.textWrapper}>
              <h3 className={teamStyles.name}>{member.name}</h3>
              <p className={teamStyles.title}>{member.title}</p>
              <a
                href={member.linkedIn}
                className={teamStyles.linkedIn}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LinkedinIcon} alt="LinkedIn" />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
