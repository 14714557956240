import * as React from "react";
import TeoWoodLogo from "./assets/teowood_logo.svg";
import EliLogo from "./assets/eli_logo.svg";
import AddictadLogo from "./assets/addictad_logo.svg";
import styles from './SuccessStories.module.css'; // Import styles as a module

const ImageGrid = ({ src, alt, className }) => (
  <div className={styles[className]}>
    <img src={src} alt={alt} className={styles.gridImage} loading="lazy" />
  </div>
);

const SuccessStories = React.forwardRef((props, ref) => {
  const images = [
    { src: AddictadLogo, alt: 'Success story image 1', className: 'largeImage' },
    { src: TeoWoodLogo, alt: 'Success story image 2', className: 'mediumImage' }, // Added missing 'alt'
    { src: EliLogo, alt: 'Success story image 3', className: 'smallImage' }
  ];

  return (
    <>
      <section ref={ref} className={styles.successStories}>
        <h2 className={styles.storiesTitle}>Câteva povești de succes</h2>
        <div className={styles.imageCollection}>
          {images.map((img, index) => (
            <ImageGrid key={index} src={img.src} alt={img.alt || 'Success story image'} className={img.className} />
          ))}
        </div>
      </section>
    </>
  );
});

export default SuccessStories;
