import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from '../../services/axios';
import { resetSession } from '../../utils/session';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { setUser, updateUser } from "../../reducers/authReducer";


function EmailVerification({ userEmail }) {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [timeLeft, setTimeLeft] = useState(120);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // check for user. if user is null but we have a token, then we need to get the user
    const user = useSelector((state) => state.auth.user);
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (user === null && token !== undefined) {
            axiosInstance.get(`/users/me`)
            .then(response => {
                dispatch(setUser(response.data));
            })
            .catch(error => {
                console.log(error);
            });
        }
    }, [user, dispatch]);

    // if we have a verification token, then we need to verify the user
    const { verificationToken } = useParams();
    const [verificationStatus, setVerificationStatus] = useState(null);
    useEffect(() => {
        if (verificationToken !== undefined && verificationStatus !== 'success') {
            axiosInstance.post(`/auth/verify/`, { "token": verificationToken })
            .then(response => {
                setVerificationStatus('success');
                // update user is_verified status
                dispatch(updateUser({ is_verified: true }));
                setTimeout(() => navigate('/feed'), 2000);  // Redirect after 3 seconds
            })
            .catch(error => {
                setVerificationStatus('error');
            });
        }
      }, [verificationToken]);

    // this is the logic for the resend email button timer
    useEffect(() => {
        let timer;
        if (isButtonDisabled) {
            timer = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
        }
        
        return () => clearInterval(timer);
    }, [isButtonDisabled]);

    useEffect(() => {
        if (timeLeft <= 0) {
            setIsButtonDisabled(false);
            setTimeLeft(120);
        }
    }, [timeLeft]);

    const handleResendEmail = () => {
        // Implement resend email logic here
        console.log('Resend email logic goes here');

        axiosInstance.get("/auth/resend-confirmation-email", { withCredentials: true })
        .then((res) => {
            console.log(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
        setIsButtonDisabled(true);
    };

    const handleUpdateEmail = () => {
        // Implement update email logic here
        console.log('Update email logic goes here');

        resetSession();
        dispatch(setUser(null));
        navigate('/signup');
    }

    const formatTime = () => {
        const minutes = Math.floor(timeLeft / 60);
        const seconds = timeLeft % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    // useEffect(() => {
    //     let view = null;
    //     // change the view according to the verification status
    //     if (verificationStatus === 'success') {
    //         view = <p>Verification successful! Redirecting to feed...</p>;
    //     } else if (verificationStatus === 'error') {
    //         view = <p>Verification failed. Please try again.</p>;
    //     } else if (user && user.is_verified) {
    //         view = <Navigate to="/feed" />;
    //     } else if (!user && verificationStatus === 'null') {
    //         view = <p>Verification successful! Redirecting to feed...</p>
    //     }  else if (user && !user.is_verified) {
    //         view = (
    //             <>
    //                 <h1 style={styles.title}>Please verify your email</h1>
    //                 <p style={styles.description}>
    //                     We sent an email to <strong>{user.email}</strong>. Click the link in the email to verify your account.
    //                 </p>
    //                 <button
    //                     onClick={handleResendEmail}
    //                     disabled={isButtonDisabled}
    //                     style={{ ...styles.button, ...(isButtonDisabled ? styles.disabledButton : {}) }}
    //                 >
    //                     {isButtonDisabled ? `Resend Email in ${formatTime()}` : 'Resend Email'}
    //                 </button>
    //                 <button
    //                     onClick={handleUpdateEmail}
    //                     style={{ ...styles.button }}
    //                 >
    //                     Update email
    //                 </button>
    //             </>
    //         );
    //     }
    // }, [verificationStatus, user, formatTime, handleResendEmail, handleUpdateEmail, isButtonDisabled]);

    console.log("Verify user: ", user);
    let view = null;
    // change the view according to the verification status
    if (verificationStatus === 'success') {
        view = <p>Verification successful! Redirecting to feed...</p>;
    } else if (verificationStatus === 'error') {
        view = <p>Verification failed. Please try again.</p>;
    } else if (user && user.is_verified) {
        view = <Navigate to="/feed" />;
    } else if (!user && verificationStatus === 'null') {
        view = <p>Verification successful! Redirecting to feed...</p>
    }  else if (user && !user.is_verified) {
        view = (
            <>
                <h1 style={styles.title}>Please verify your email</h1>
                <p style={styles.description}>
                    We sent an email to <strong>{user.email}</strong>. Click the link in the email to verify your account.
                </p>
                <button
                    onClick={handleResendEmail}
                    disabled={isButtonDisabled}
                    style={{ ...styles.button, ...(isButtonDisabled ? styles.disabledButton : {}) }}
                >
                    {isButtonDisabled ? `Resend Email in ${formatTime()}` : 'Resend Email'}
                </button>
                <button
                    onClick={handleUpdateEmail}
                    style={{ ...styles.button }}
                >
                    Update email
                </button>
            </>
        );
    } else {
        view = <p>Verification in progress...</p>;
    }

    return (
        <div style={styles.container}>
            {view}

            {/* {user && user.is_verified && <Navigate to="/feed" />}
            {!user && verificationStatus === 'null' && <p>Verification in progress...</p>}
            {verificationStatus === 'success' && <p>Verification successful! Redirecting to feed...</p>}
            {verificationStatus === 'error' && <p>Verification failed. Please try again.</p>}

            <h1 style={styles.title}>Please verify your email</h1>
            <p style={styles.description}>
                We sent an email to <strong>{user.email}</strong>. Click the link in the email to verify your account.
            </p>
            <button
                onClick={handleResendEmail}
                disabled={isButtonDisabled}
                style={{ ...styles.button, ...(isButtonDisabled ? styles.disabledButton : {}) }}
            >
                {isButtonDisabled ? `Resend Email in ${formatTime()}` : 'Resend Email'}
            </button>
            <button
                onClick={handleUpdateEmail}
                style={{ ...styles.button }}
            >
                Update email
            </button> */}
        </div>
    );
}

const styles = {
    container: {
        textAlign: 'center',
        padding: '20px',
    },
    title: {
        fontSize: '24px',
        marginBottom: '10px',
    },
    description: {
        fontSize: '16px',
        marginBottom: '20px',
    },
    button: {
        margin: '10px',
        padding: '10px 15px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    disabledButton: {
        backgroundColor: '#ccc',
        cursor: 'not-allowed',
    },
};

export default EmailVerification;
