import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerUser, loginUser } from "../../reducers/authReducer";
import { error2message, validateEmail } from "../../utils/general";
import styles from "./auth.module.css"; // Importing the custom CSS module
import axiosInstance from "../../services/axios";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  useToast,
  Box,
  Icon,
} from "@chakra-ui/react";
import { FaGoogle, FaLinkedin } from "react-icons/fa";

function Signup2() {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm();

  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    try {
      await dispatch(registerUser(values));
      toast({
        title: "Contul a fost creat cu success. Verifica adresa de mail.",
        status: "success",
        isClosable: true,
        duration: 2000,
      });
      await dispatch(loginUser(values));
      navigate("/verify", { replace: true });
    } catch (error) {
      toast({
        title: error2message(error),
        status: "error",
        isCloseable: true,
        duration: 2000,
      });
    }
  };

  const handleOAuth = (provider) => {
    axiosInstance
      .get(`/auth/${provider}/authorize`)
      .then((res) => {
        console.log(res.data);
        window.open(res.data.authorization_url, "_self");
      });
  };

  return (
    <Flex height="100vh" alignItems="center" justifyContent="center">
      <Flex
        className={styles.registerContainer}
        direction="column"
        alignItems="center"
        background="#eee"
        p={12}
        rounded={6}
      >
        <Heading className={styles.registerHeading} mb={6}>
          Creează-ti un cont
        </Heading>
        <p className={styles.subheader}>
          Stai la curent cu oportunitătile tale financiare
        </p>
        <form className={styles.registerForm} onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors.email}>
            <Input
              className={styles.registerInput}
              placeholder="Email"
              background="#fff"
              type="email"
              size="lg"
              mt={6}
              {...register("email", {
                required: "Acest camp este obligatoriu",
                validate: validateEmail,
              })}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.password}>
            <Input
              className={styles.registerInput}
              placeholder="Parola (8+ caractere)"
              background="#fff"
              type="password"
              size="lg"
              mt={6}
              {...register("password", {
                required: "Acest camp este obligatoriu",
                minLength: {
                  value: 5,
                  message: "Parola trebuie sa aiba cel putin 8 caractere",
                },
                maxLength: {
                  value: 24,
                  message: "Parola trebuie sa aiba cel mult 24 de caractere",
                },
              })}
            />
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.verifyPassword}>
            <Input
              className={styles.registerInput}
              placeholder="Confirmare parola"
              background="#fff"
              type="password"
              size="lg"
              mt={6}
              {...register("verifyPassword", {
                validate: (value) =>
                  value === getValues("password") ||
                  "Parolele nu sunt identice",
              })}
            />
            <FormErrorMessage>
              {errors.verifyPassword && errors.verifyPassword.message}
            </FormErrorMessage>
          </FormControl>
          <p className={styles.agreement}>
            {" "}
            Prin apăsarea butonului „Acceptă și Înscrie-te”, ești de acord cu
            Termenii și Condițiile{" "}
            <a href="/user-agreement">Acordul Utilizatorului</a>.
          </p>
          <Button
            isLoading={isSubmitting}
            loadingText="Se creaza contul..."
            className={styles.registerButton}
            width="100%"
            type="submit"
          >
            Acceptă și Înscrie-te
          </Button>
          <Box mt={6}>
            <Button
              leftIcon={<Icon as={FaGoogle} color="red.500" />}
              onClick={() => handleOAuth("google")}
              backgroundColor="#F5F5F5" // Light gray background
              colorScheme="red" // Subtle hint of Google red on hover
              color="gray.600" // Dark gray text for better contrast
              _hover={{ bg: "#EDEDED", color: "red.500" }} // Lighter gray on hover with red text
              width="100%"
              mb={2}
            >
              Sign up with Google
            </Button>
            <Button
              leftIcon={<Icon as={FaLinkedin} color="blue.500" />}
              onClick={() => handleOAuth("linkedin")}
              backgroundColor="#F5F5F5" // Light gray background
              colorScheme="blue" // Subtle hint of LinkedIn blue on hover
              color="gray.600" // Dark gray text for better contrast
              _hover={{ bg: "#EDEDED", color: "blue.500" }} // Lighter gray on hover with blue text
              width="100%"
            >
              Sign up with LinkedIn
            </Button>
          </Box>
          <Button
            onClick={() => navigate("/signin", { replace: true })}
            width="100%"
            mt={6}
          >
            Către autentificare
          </Button>
        </form>
      </Flex>
    </Flex>
  );
}

export default Signup2;
