import React, { createContext, useState, useContext, useEffect } from 'react';
import axiosInstance from './services/axios';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [threadId, setThreadId] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [messages, setMessages] = useState([]);

  const initializeChat = async () => {
    if (!isInitialized) {
      try {
        const response = await axiosInstance.get('/chat/initiate/index', { withCredentials: true });
        const data = response.data;
        setThreadId(data.thread_id);
        setIsInitialized(true);
        setMessages([{ 
          text: "Sunt conectat la baza de cunoștințe. Pot să încep să caut pentru tine oportunitățile potrivite.", 
          sender: 'bot' 
        }]);
      } catch (error) {
        console.error('Error initializing chat:', error);
      }
    }
  };

  useEffect(() => {
    initializeChat();
  }, []);

  const addMessage = (message) => {
    setMessages(message); //(prevMessages => [...prevMessages, message]);
  };

  return (
    <ChatContext.Provider value={{ threadId, isInitialized, messages, addMessage }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);
