import React from "react";
import styles from "./FundInfo.module.css";
import { Link } from "react-router-dom";

const FundInfo = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className={styles.container}>
      <div className={styles.section}>
        <h2 className={styles.title}>Despre ctrl.fund</h2>
        <p className={styles.description}>
          Toate oportunitățile de finanțare...
          <br />O singură platformă
        </p>
        <Link
          to="/aboutus"
          className={styles.moreInfoButton}
          onClick={() => {
            window.scrollTo(0, 0); // Scroll to top when clicking on the link
          }}
        >
          Vezi mai multe despre noi
        </Link>
      </div>
      <div className={styles.section}>
        <h2 className={styles.titleItalic}>Facem finantarea mai accesibila</h2>
        <p className={styles.descriptionSmall}>
          Scopul principal al platformei ctrl.Fund este de a crește, îmbunătăți
          și simplifica accesul mediului de afaceri la finanțare, prin
          digitalizare, transparență și simplificarea proceselor, deoarece
          credem în finanțarea inclusivă.
        </p>
      </div>
      <div className={styles.section}>
        <h2 className={styles.titleItalic}>Cum vedem lucrurile</h2>
        <p className={styles.descriptionSmall}>
          Viziunea noastră plasează ctrl.Fund printre soluțiile Fintech
          inovatoare, utilizând tehnologia pentru a îmbunătăți incluziunea
          financiară a afacerilor într-un ecosistem complex și dispersat. Ne
          propunem să devenim un „one-stop-shop” pe piața finanțărilor, oferind
          o platformă digitală revoluționară pentru agregarea completă a
          produselor financiare dedicate afacerilor.
        </p>
      </div>
    </div>
  );
});

export default FundInfo;
