import { combineReducers } from "redux";
import userReducer from "./userReducer";
import articleReducer from "./articleReducer";
import authReducer from './authReducer';
import dummyReducer from './dummyReducer';

const rootReducer = combineReducers({
	userState: userReducer,
	articleState: articleReducer,
	auth: authReducer,
	dummyState: dummyReducer,
});

export default rootReducer;
