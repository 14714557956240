import React, { useState, useEffect } from "react";
import { Container, Progress, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

function Step3Content({ cui }) {
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Set up WebSocket or SSE connection to receive progress updates
    const eventSource = new EventSource(`${process.env.REACT_APP_BASE_URL}/companies/progress/${cui}`, { ///api/v1
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
      },
    });
    
    eventSource.onmessage = (event) => {
      const newProgress = JSON.parse(event.data).progress;
      setProgress(newProgress);
      
      if (newProgress === 100) {
        eventSource.close();
        setTimeout(() => navigate("/feed"), 1000); // Redirect after 1 second
      }
    };

    return () => {
      eventSource.close();
    };
  }, [cui, navigate]);

  return (
    <Container
      borderRadius={10}
      marginTop={15}
      padding={5}
      maxW="800"
      backgroundColor="white"
      alignContent="center"
    >
      <VStack spacing={4}>
        <Text>Analizăm toate finanțările...</Text>
        <Progress
          value={progress}
          width="100%"
          colorScheme="green"
          hasStripe
          isAnimated
        />
        <Text>{Math.round(progress)}% completat</Text>
      </VStack>
    </Container>
  );
}

export default Step3Content;
