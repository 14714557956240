import React from "react";

const CircularScore = ({ score }) => {
  const radius = 90; // Radius of the circle
  const circumference = 2 * Math.PI * radius; // Calculate the circumference
  const maxScore = 102; // Maximum score
  // Calculate the strokeDashoffset based on the score
  const strokeDashoffset = circumference - (score / maxScore) * circumference;

  const getCircleColor = (score) => {
    if (score >= 75) {
      return 'green'; // Green for high scores
    } else if (score >= 50) {
      return '#f1c40f'; // Yellow for medium scores
    } else {
      return '#e74c3c'; // Red for low scores
    }
  };
  const circleColor = getCircleColor(score);

  return (
    <div>
      <svg
        width="75"
        height="75"
        viewBox="-25 -25 250 250"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: "rotate(-90deg)" }}
      >
        <circle
          r="90"
          cx="100"
          cy="100"
          fill="transparent"
          stroke="#e0e0e0"
          strokeWidth="16px"
          strokeDasharray={`${circumference}px`}
          strokeDashoffset="0"
        ></circle>
        <circle
          r="90"
          cx="100"
          cy="100"
          stroke={circleColor}
          strokeWidth="16px"
          strokeLinecap="round"
          strokeDashoffset={`${strokeDashoffset}px`}
          fill="transparent"
          strokeDasharray={`${circumference}px`}
        ></circle>
        <text
          x="47px"
          y="129px"
          fill={circleColor}
          fontSize="100px"
          fontWeight="bold"
          style={{ transform: "rotate(90deg) translate(0px, -196px)" }}
        >
          {score}
        </text>
      </svg>
    </div>
  );
};

export default CircularScore;
