import React from "react";
import HorizontalTimeline from "react-horizontal-timeline";
import "./Timeline.css";

const EXAMPLE = [
  {
    data: "2024-02-03",
    status: "Depunere",
  },
  {
    data: "2024-03-06",
    status: "Acum",
  },
  {
    data: "2024-07-25",
    status: "Deadline",
  },
];
const today = new Date(); // Current date

export default class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curIdx: 1,
      prevIdx: -1,
    };
  }

  //state = { value: 0, previous: 0 };

  render() {
    const { curIdx, prevIdx } = this.state;
    const curStatus = EXAMPLE[curIdx].status;
    const prevStatus = prevIdx >= 0 ? EXAMPLE[prevIdx].status : "";

    return (
      <div>
        <div
          style={{
            width: "100%",
            height: "100px",
            margin: "0 auto",
            marginTop: "20px",
          }}
        >
          <HorizontalTimeline
            styles={{
              background: "#f8f8f8",
              foreground: "#1A79AD",
              outline: "lightgray",
            }}
            isOpenBeginning={true}
            isOpenEnding={true}
            isTouchEnabled={true}
            index={this.state.curIdx}
            indexClick={(index) => {
              const curIdx = this.state.curIdx;
              this.setState({ curIdx: index, prevIdx: curIdx });
            }}
            values={EXAMPLE.map((x) => x.data)}
            getLabel={(date, index) => {
              // Assuming the date format is YYYY-MM-DD and converting it to a more readable format
              const formattedDate = new Date(date).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              });
              return (
                <div style={{ textAlign: "center" }}>
                  <div
                    style={{
                      maxWidth: "100px", // Adjust based on your needs
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      cursor: "default", // Change cursor to indicate to users they can hover to see full text
                      title: EXAMPLE[index].status, // Tooltip showing full text on hover
                    }}
                  >
                    {EXAMPLE[index].status}
                  </div>
                  <div style={{ fontSize: "8px" }}>{formattedDate}</div>
                </div>
              );
            }}
            maxEventPadding={150}
            minEventPadding={10}
            labelWidth={100}
            linePadding={50}
          />
        </div>
      </div>
    );
  }
}
