import NavBar from "../../../Components/NavBar";
import axiosInstance from "../../../../services/axios";
import { Tr, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import { useEffect, useState } from "react";
import Step1Content from "./Step1Content";
import Step2Content from "./Step2Content";
import Step3Content from "./Step3Content";
import Step4Content from "./Step4Content";
// import onboardStyles from "./OnboardCompany.module.css";
// import "./OnboardCompany.css";
import ReactLoading from "react-loading";

function Onboard() {
  const [switchStep, setSwitchStep] = useState(1);
  const [formData, setFormData] = useState({
    step1: {}, // Data from Step 1
    step2: {}, // Data from Step 2
    step3: {}, // Data from Step 3
    step4: {}, // Data from Step 4
  });
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const toast = useToast();
  const navigate = useNavigate(); // Hook for redirection

  const handleAutocomplete = async (cui) => {
    const url = `/companies/full/${cui}`;
    axiosInstance
      .get(url)
      .then((response) => {
        // console.log(response.data);
        setFormData({
          step1: {
            cui: response.data.cif,
            companyName: response.data.denumire,
            registrationDate: response.data.registration_date.split("T")[0],
            registrationNumber: response.data.numar_reg_com,
            companyAddress: response.data.adresa,
          },
          step2: {
            companyDescription: response.data.description,
            authorizedCaenCodes: response.data.authorized_caen_codes[0],
          },
          step3: {},
          extra: {
            companyRegion: response.data.judet,
            companyCategory: response.data.category,
          },
        });
      })
      .catch((error) => {
        toast({
          title: "Error fetching company data",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const handleSubmit = async () => {
    setIsLoading(true); // Start loading
    try {
      const saveResponse = await axiosInstance.post(
        "/companies/save",
        flattenDictionary(formData)
      );
      // console.log(saveResponse.data); // Handle success from save operation

      const scoreResponse = await axiosInstance.get("/companies/scorefundings"); // Adjust URL as necessary
      if (scoreResponse.status === 200 && scoreResponse.data === true) {
        navigate("/feed"); // Redirect to the feed, adjust the path as necessary
      } else {
        // console.log(scoreResponse.data); // Handle scoring failure
        throw new Error("Funding scoring failed."); // Handle scoring failure
      }
    } catch (error) {
      console.error(error); // Handle error
      toast({
        title: "Error processing request",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false); // End loading regardless of outcome
    }
  };

  useEffect(() => {
    if (isLoading) {
      // Display loading screen/modal here. You can use a modal, full page overlay, etc.
      // This is just a placeholder logic to indicate where you might implement the visual feedback.
      // console.log("Finding the right fundings for you...");
    }
  }, [isLoading]);

  function flattenDictionary(dict) {
    const flattened = {};

    for (const [key, value] of Object.entries(dict)) {
      if (typeof value === "object" && value !== null) {
        for (const [nestedKey, nestedValue] of Object.entries(value)) {
          flattened[`${nestedKey}`] = nestedValue;
        }
      } else {
        flattened[key] = value;
      }
    }

    return flattened;
  }

  function stepToShow() {
    // setSwitchStep();
    if (switchStep === 1) {
      return (
        <Step1Content
          handleAutocomplete={handleAutocomplete}
          data={formData.step1}
          updateData={(newData) => setFormData({ ...formData, step1: newData })}
          setSwitchStep={setSwitchStep}
        />
      );
    } else if (switchStep === 2) {
      return (
        <Step2Content
          data={formData.step2}
          updateData={(newData) => setFormData({ ...formData, step2: newData })}
          setSwitchStep={setSwitchStep}
        />
      );
    } else if (switchStep === 3) {
      return <Step3Content setSwitchStep={setSwitchStep} />;
    } else if (switchStep === 4) {
      return (
        <Step4Content
          handleSubmit={handleSubmit}
          setSwitchStep={setSwitchStep}
        />
      );
    }
  }

  return (
    <div className="main-div">
      <NavBar />
      {isLoading ? (
        <div className="loading-page">
          <ReactLoading
            type={"bars"}
            color={"#03fc4e"}
            height={100}
            width={100}
          />
          Finding the right fundings for you...
        </div>
      ) : (
        <div>
          <div className="steps-add-company">
            <h3>Adauga o companie</h3>
            <div className="num-add-company">
              <div className="add-container">
                {switchStep > 1 ? (
                  <img className="check-img" src="/images/check.png" alt="" />
                ) : (
                  <p className="add-number">1</p>
                )}
                <p className="add-title">Date generale</p>
              </div>
              <div className="add-container">
                {switchStep > 2 ? (
                  <img className="check-img" src="/images/check.png" alt="" />
                ) : (
                  <p className="add-number">2</p>
                )}
                <p className="add-title">Configurare profil</p>
              </div>
              <div className="add-container">
                {switchStep > 3 ? (
                  <img className="check-img" src="/images/check.png" alt="" />
                ) : (
                  <p className="add-number">3</p>
                )}
                <p className="add-title">Incepem aici</p>
              </div>
              <div className="add-container">
                <p className="add-number">4</p>
                <p className="add-title">Incepem aici</p>
              </div>
            </div>
          </div>
          {stepToShow()}
        </div>
      )}
    </div>
  );
}

export default Onboard;
