import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FaBuilding, FaRegBookmark } from "react-icons/fa";
import leftStyle from "./NavigationDrawer2.module.css";
import { setCompaniesSuccess, setCurrentCompany } from "../../reducers/authReducer";
import axiosInstance from "../../services/axios";

function Left() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const companies = useSelector((state) => state.auth.companies);
  const current_company_id = useSelector((state) => state.auth.current_company_id);
  let photoUrl = user && user.profile_picture ? user.profile_picture : "/images/user.svg";

  const handleCompanyClick = (companyId) => {
    dispatch(setCurrentCompany(companyId));
    console.log("companyId", companyId);
    // refresh the page
    window.location.reload();
    // refresh just the feed and the chat
  };

  useEffect(() => {
    if (!companies) {
      axiosInstance
        .get("/companies/getAll", { withCredentials: true })
        .then((res) => {
          dispatch(setCompaniesSuccess(res.data));
          console.log("companies", res.data);
          if (current_company_id == null && companies.length > 0) {
            dispatch(setCurrentCompany(companies[0]._id));
          }
        })
        .catch((error) => {
          console.error("Could not fetch companies", error);
        });
    }
  }, [dispatch]);

  return (
    <>
      <button className={leftStyle.menuButton}></button>
      <div className={leftStyle.navDrawer}>
        <div className={leftStyle.artCard}>
          <div className={leftStyle.userInfo}>
            <div className={leftStyle.cardBackground} />
            <Link to="/profile">
              <div
                className={leftStyle.photo}
                style={{ backgroundImage: `url(${photoUrl})` }}
              />
              <div className={leftStyle.link}>
                Salutare{user && user.first_name ? ", " + user.first_name : ""}!
              </div>
            </Link>
            <Link to="/profile">
              <div className={leftStyle.addPhotoText}>
                Configureaza profilul
              </div>
            </Link>
          </div>
          <div className={leftStyle.divider} />
          <div className={leftStyle.section}>
            <div className={leftStyle.sectionHeader}>Companiile mele</div>
            <div className={leftStyle.companiesList}>
              {companies && companies.length > 0 ? (
                <>
                  {companies.map((company) => (
                    <div
                      key={company._id}
                      className={`${leftStyle.navItem} ${current_company_id === company._id ? leftStyle.selectedNavItem : ""}`}
                      onClick={() => handleCompanyClick(company._id)}
                    >
                      <div
                        className={leftStyle.companyLink}
                      >
                        {company.company_name}
                      </div>
                    </div>
                  ))}
                  <Link to="/onboard2" className={leftStyle.addCompanyMessage}>
                    Mai adauga o companie
                  </Link>
                </>
              ) : (
                <Link to="/onboard2" className={leftStyle.addCompanyMessage}>
                  Adauga o companie
                </Link>
              )}
            </div>
          </div>
          <div className={leftStyle.divider} />
          <div className={leftStyle.section}>
            <Link to="/saved-fundings" className={leftStyle.savedFundingsLink}>
              <FaRegBookmark className={leftStyle.icon} />
              Finanțări salvate
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Left;
