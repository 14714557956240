import { useState } from "react";
import React from "react";

function Step4Content({ handleSubmit, setSwitchStep }) {
  const [finantare, setFinantare] = useState(false);
  const [historyFinance, setHistoryFinance] = useState(false);

  function clickHandleYes() {
    setFinantare(true);
  }
  function clickHandleNo() {
    setFinantare(false);
  }
  function clickHandleYesHistoy() {
    setHistoryFinance(true);
  }
  function clickHandleNoHistoy() {
    setHistoryFinance(false);
  }

  // previous button
  function handlePropPrev() {
    setSwitchStep(3);
  }
  return (
    <div className="add-company-form">
      <div className="first-step">
        <p>Esti interesat de finantari?</p>
        <div className="">
          <button onClick={clickHandleYes} className="buttons-general">
            Da
          </button>
          <button onClick={clickHandleNo} className="buttons-general">
            Nu
          </button>
        </div>
        {finantare && (
          <form>
            <label>De care anume?</label>
            <input className="input-finance" type="text" />
          </form>
        )}
        <p>Ai mai accesat finantari?</p>
        <div>
          <button onClick={clickHandleYesHistoy} className="buttons-general">
            Da
          </button>
          <button onClick={clickHandleNoHistoy} className="buttons-general">
            Nu
          </button>
        </div>
        {historyFinance && (
          <form>
            <label>Ce anume ai mai accesat?</label>
            <input className="input-finance" type="text" />
          </form>
        )}
        <div className="step-buttons">
          <button onClick={handlePropPrev} className="previous-step-button">
            Pasul anterior
          </button>
          <button onClick={handleSubmit} className="submit">
            Submit
          </button>
        </div>
      </div>

      {/* instructiuni adaugare companie */}
      <div className="instructions">
        <p>
          Adăuga o scurta descriere a companiei (care este domeniul de
          activitate, principalele produse/servicii). De exemplu: Societatea X
          SRL este un producator de mobila din Valcea, care fabrica mese si
          scaune pt birou.
        </p>
      </div>
    </div>
  );
}

export default Step4Content;
