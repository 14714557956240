import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { getUser } from "../../reducers/authReducer";
import { error2message } from "../../utils/general";
import axiosInstance from "../../services/axios";
import { setSession } from '../../utils/session';


const OAuthCallback = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const dispatch = useDispatch();
  const hasHandledOAuth = useRef(false); // This flag prevents double execution

  useEffect(() => {
    const handleOAuth = async () => {
      if (hasHandledOAuth.current) return; // Exit if already handled
      hasHandledOAuth.current = true; // Set flag to true after first execution

      try {
        const query = new URLSearchParams(window.location.search);

        // decide if the callbackUrl is for google or linkedin
        const provider = query.toString().includes("google") ? "google" : "linkedin";
        const callbackUrl = `/auth/${provider}/callback?${query.toString()}`; // /api/v1

        // set token
        const response = await axiosInstance.get(callbackUrl);
        if (response.status !== 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.data;
        setSession(data.access_token);
        localStorage.setItem("accessToken", data.access_token);

        // get user
        const verified = await dispatch(getUser());
        toast({
          title: "Te-ai autentificat cu succes!",
          status: "success",
          isClosable: true,
          duration: 2500,
        });
        setLoading(false);
        if (verified) {
          navigate("/feed", { replace: true });
        } else {
          navigate("/verify", { replace: true });
        }
      } catch (error) {
        let errorTitle = error2message(error);
        toast({
          title: errorTitle,
          status: "error",
          isCloseable: true,
          duration: 3000,
        });
        console.error("Error handling OAuth:", error);
        setLoading(false);
        navigate("/signup");
      }
    };

    handleOAuth();
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return null;
};

export default OAuthCallback;
