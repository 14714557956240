// IncubatorList.js
import React from 'react';
import './IncubatorList.css'; // Importing a CSS file for styling

const IncubatorList = ({ data }) => {
  return (
    <div className="incubatorList">
      {data.map((item, index) => (
        <div key={index} className="incubatorItem">
          <h2>{item['City/Location']}</h2>
          <p>Status: {item.Status}</p>
          <a href={item['Contact Information']} target="_blank" rel="noopener noreferrer">More Info</a>
        </div>
      ))}
    </div>
  );
};

export default IncubatorList;
