import React from "react";
import stylesIntroduction from "./Introduction.module.css";

const Introduction = () => {
  return (
    <div className={stylesIntroduction.container}>
      <div className={stylesIntroduction.content}>
        <p>
          <span className={stylesIntroduction.highlight}>ctrl.fund</span> a aparut ca urmare a nevoii de finanțare pentru
          întreprinderi într-un peisaj vast și complex de oportunitati. În
          ultimii ani, diversificarea surselor de finanțare și trecerea la
          digitalizare au contribuit la o gamă largă de alternative viabile
          pentru afaceri, incluzând fonduri europene, finanțare guvernamentală,
          investitori privați, crowdfunding, etc.
        </p>
        < br />
        <p>
          Astfel, platforma ctrl.fund aduce multitudinea de oportunitati de
          finantare intr-un singur loc si conecteaza nevoile de finantare ale
          business-urilor cu cele mai potrivite finantari disponibile.
        </p>
      </div>
    </div>
  );
};

export default Introduction;
