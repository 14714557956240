import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import FundingCard2 from "./FundingCard/FundingCard2";
import axiosInstance from "../../services/axios";
import OnboardingCard from "./Onboarding/CompanyOnboard2/OnboardingCard";

function FundingScroll(searchResults, setSearchResults, searchFilters) {
  // const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const user = useSelector((state) => state.auth.user);

  const fetchMoreData = () => {
    if (!hasMore) return;

    const url = `/fundings/load/${searchResults.length}`;

    axiosInstance
      .get(url, { withCredentials: true })
      .then((response) => response.data)
      .then((newItems) => {
        if (newItems.length === 0) {
          setHasMore(false);
          return;
        }
        // setItems((prevItems) => [...prevItems, ...newItems]);
        setSearchResults([...searchResults, ...newItems]);
        // console.log(newItems[0]);
      })
      .catch((error) => console.error("Error:", error));
  };

  // Fetch initial data on mount
  useEffect(() => {
    fetchMoreData();
  }, []);

  console.log("user companies: ", user.companies);

  return (
    <div>
      {user && user.companies.length === 0 && <OnboardingCard />}
      <InfiniteScroll
        dataLength={searchResults.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        {searchResults.map((item, index) => (
          <FundingCard2 key={index} funding={item} />
        ))}
      </InfiniteScroll>
    </div>
  );
}

export default FundingScroll;
