import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import feed from "./Feed.module.css";
import Right from "./Right";
import Header from "./Header";
import mainStyles from "./Main.module.css";
import Left from "./Left";
import FundingScroll from "./FundingScroll";
import { loadUserFromStorage } from "../../reducers/authReducer";

function Feed() {
  const dispatch = useDispatch();
  const [searchResults, setSearchResults] = useState([]);
  const [searchFilters, setSearchFilters] = useState({
    status: "all",
    region: "all",
    startDate: null,
    endDate: null,
    sortBy: "relevance",
    sortOrder: "desc"
  });

  const handleSearchResults = (results, filters) => {
    console.log("Search results in Feed:", results);
    setSearchResults(results);
    setSearchFilters(filters);
  };

  useEffect(() => {
    dispatch(loadUserFromStorage());
  }, [dispatch]);

  useEffect(() => {
    console.log("Search results updated:", searchResults);
  }, [searchResults]);

  const user = useSelector((state) => state.auth.user);
  const isLoading = useSelector((state) => state.auth.isLoading);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/" replace />;
  }

  if (user && !user.is_verified) {
    return <Navigate to="/verify" replace />;
  }

  return (
    <div className={feed.container}>
      <Header setSearchResults={handleSearchResults} user={user} />
      <div className={feed.content}>
        <div className={feed.layout}>
          <div className={`${feed.fixed}`}>
            <Left />
          </div>
          <div className={mainStyles.container}>
            <FundingScroll searchResults={searchResults} setSearchResults={setSearchResults} searchFilters={searchFilters} />
          </div>
          <Right />
        </div>
      </div>
    </div>
  );
}

export default Feed;
