import { FormControl, FormLabel, Grid, Input, Select } from "@chakra-ui/react";

function AccountSettings() {
  return (
    <Grid
      templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
      gap={6}
    >
      <FormControl id="firstName">
        <FormLabel>Nume</FormLabel>
        <Input focusBorderColor="brand.blue" type="text" placeholder="Tim" />
      </FormControl>
      <FormControl id="lastName">
        <FormLabel>Prenume</FormLabel>
        <Input focusBorderColor="brand.blue" type="text" placeholder="Cook" />
      </FormControl>
      <FormControl id="phoneNumber">
        <FormLabel>Numar telefon</FormLabel>
        <Input
          focusBorderColor="brand.blue"
          type="tel"
          placeholder="(+40) 774071234"
        />
      </FormControl>
      <FormControl id="emailAddress">
        <FormLabel>Email</FormLabel>
        <Input
          focusBorderColor="brand.blue"
          type="email"
          placeholder="tcook@apple.com"
          disabled
        />
      </FormControl>
      <FormControl id="job">
        <FormLabel>Functie</FormLabel>
        <Input
          focusBorderColor="brand.blue"
          type="text"
          placeholder="marketing manager"
        />
      </FormControl>
    </Grid>
  );
}

export default AccountSettings;
