import React from "react";
import {
  Button,
  Flex,
  Text,
  Box,
  Collapse,
  List,
  ListItem,
  useDisclosure,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

const ColoredExpandableText = ({ title, text_list, color, eligibilities, eligibility_scores }) => {
  const { isOpen, onToggle } = useDisclosure();
  if (!text_list) text_list = [];

  if (title === "Criterii de eligibilitate") {
    text_list = [
      "Se afla in una din categoriile: " + eligibilities[0],
      "Se afla in regiunea vizata: " + eligibilities[1],
      "Activeaza in una din urmatoarele coduri CAEN: " + eligibilities[2],
      ...text_list,
    ];
  }

  // Function to determine text color based on eligibility score
  const getTextColor = (index) => {
    if (eligibility_scores && !eligibility_scores[index] && index < 3) {
      return "red.500"; // Red color from Chakra UI color palette
    }
    return "black"; // Default text color
  };

  return (
    <div>
      <Flex flexDirection="column" justifyContent="space-between" my="2">
        <Box
          bg={color}
          h="2px"
          width="full"
          borderTopRadius={25}
          marginBottom={0}
        ></Box>
        <Button
          width="100%"
          rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          onClick={onToggle}
          variant="ghost"
          justifyContent="space-between"
          fontWeight="normal"
          height={8}
          shadow={isOpen ? "none" : "sm"}
        >
          {title}
        </Button>
      </Flex>
      <Collapse in={isOpen}>
        <Box shadow="lg" p="4" borderRadius="md" bg="white">
          <List spacing={2}>
            {text_list.map((criteriu, index) => (
              <ListItem key={index} display="flex" alignItems="center">
                <Box
                  flexShrink="0"
                  h="8px"
                  w="8px"
                  borderRadius="full"
                  bg="gray"
                  mr="2"
                ></Box>
                <Text color={getTextColor(index)}>{criteriu}</Text>
              </ListItem>
            ))}
          </List>
        </Box>
      </Collapse>
    </div>
  );
};

export default ColoredExpandableText;
