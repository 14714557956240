import React from 'react';
import IncubatorList from './IncubatorList';
import FilterComponent from './FilterComponent';
import MapComponent from './MapComponent'; // Import the MapComponent
import data from './incubatoare.json'; // Assume your JSON data is saved here

function Incubators() {
  const [filters, setFilters] = React.useState({
    city: '',
    duration: '',
    status: ''
  });
  const [filteredData, setFilteredData] = React.useState(data);

  React.useEffect(() => {
    let filtered = data;
    if (filters.city) {
      filtered = filtered.filter(item => item['City/Location'].includes(filters.city));
    }
    if (filters.status) {
      filtered = filtered.filter(item => item.Status.includes(filters.status));
    }
    setFilteredData(filtered);
  }, [filters]);

  return (
    <div className="App">
      <h1>Welcome to the Business Incubator Showcase</h1>
      <FilterComponent filters={filters} setFilters={setFilters} />
      <IncubatorList data={filteredData} />
      <MapComponent data={filteredData} />
    </div>
  );
}

export default Incubators;
