import React, { useState, useEffect, useRef } from 'react';
import { useChat } from '../../ChatContext';
import styles from './Chat.module.css';
import { Button, Spinner, Icon, Avatar } from "@chakra-ui/react"
import { IoSend, IoClose } from "react-icons/io5";
import { RiRobot2Fill } from "react-icons/ri";
import axiosInstance from '../../services/axios';

function Chat({ fundingId, onClose }) {
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const { threadId, isInitialized, messages, addMessage } = useChat();
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const sendMessage = async () => {
        if (!input.trim() || !threadId) return;

        setIsLoading(true);
        addMessage((prevMessages) => [...prevMessages, { text: input, sender: 'user' }]);
        setInput('');

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/chat`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ message: input, thread_id: threadId, funding_id: fundingId }),
            });
    
            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');
            let botMessage = '';
    
            setIsTyping(true);
    
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                const chunk = decoder.decode(value, { stream: true });
                
                // Force immediate processing of each chunk
                await new Promise(resolve => setTimeout(resolve, 0));
                
                botMessage += chunk;
                const currentMessage = botMessage;
                addMessage((prevMessages) => {
                    const lastMessage = prevMessages[prevMessages.length - 1];
                    
                    if (lastMessage?.sender === 'bot') {
                        return prevMessages.map((msg, index) => 
                            index === prevMessages.length - 1 
                                ? { ...msg, text: currentMessage }
                                : msg
                        );
                    } else {
                        return [...prevMessages, { text: currentMessage, sender: 'bot' }];
                    }
                });
            }
        } catch (error) {
            console.error('Error sending message:', error);
            addMessage((prevMessages) => [...prevMessages, { text: 'Error sending message', sender: 'system' }]);
        } finally {
            setIsTyping(false);
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.chatContainer}>
            <div className={styles.chatHeader}>
                <div className={styles.chatHeaderLeft}>
                    <Avatar icon={<RiRobot2Fill />} bg="blue.500" color="white" size="sm" mr={2} />
                    <span>Asistent AI de cautare</span>
                </div>
                <Button onClick={onClose} className={styles.closeButton}>
                    <IoClose />
                </Button>
            </div>
            <div className={styles.chatDescription}>
                Sunt un asistent AI proiectat să te ajute să găsești oportunitatea de finanțare potrivită. Întreabă-mă orice despre oportunitățile de finanțare disponibile!
            </div>
            <div className={styles.messagesContainer}>
                {messages.map((msg, index) => (
                    <div key={index} className={`${styles.message} ${styles[msg.sender]}`}>
                        {msg.sender === 'bot' && (
                            <Avatar icon={<RiRobot2Fill />} bg="blue.500" color="white" size="xs" mr={2} />
                        )}
                        <div className={styles.messageBubble}>{msg.text}</div>
                    </div>
                ))}
                {isTyping && (
                    <div className={`${styles.message} ${styles.bot}`}>
                        <div className={`${styles.messageBubble} ${styles.typing}`}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                )}
                <div ref={messagesEndRef} />
            </div>
            <div className={styles.inputContainer}>
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                    disabled={isLoading}
                    placeholder="Întreabă despre oportunități de finanțare..."
                />
                <Button 
                    onClick={sendMessage} 
                    disabled={isLoading}
                    className={styles.sendButton}
                >
                    {isLoading ? <Spinner size="sm" /> : <Icon as={IoSend} />}
                </Button>
            </div>
        </div>
    );
}

export default Chat;
