import { createSlice } from '@reduxjs/toolkit';
import { useToast } from "@chakra-ui/react";
import { setSession, resetSession, isSessionSet } from '../utils/session';
import axiosInstance from '../services/axios';

const initialState = {
    isAuthenticated: false,
    user: null,
    isLoading: true, // Add a loading state
    companies: null,
    filters: {
        status: "all",
        region: "all",
        startDate: null,
        endDate: null,
        sortBy: "relevance",
        sortOrder: "desc",
        eligibility: "all"
    }
};

export const authReducer = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        registerSuccess: (state, action) => {
            state.isRegistered = true;
        },
        loginSuccess: (state, action) => {
            state.isAuthenticated = true;
            state.user = action.payload;
        },
        loadUserFromStorage: (state) => {
            const storedUser = JSON.parse(localStorage.getItem("user"));
            if (storedUser) {
                state.isAuthenticated = true;
                state.user = storedUser;
            }
            state.isLoading = false; // User data is loaded, set loading to false
            state.current_company_id = localStorage.getItem("current_company_id") || null;
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.user = null;
            state.isLoading = false; // Set loading to false on logout
            localStorage.removeItem("user"); // Clear user from local storage
            localStorage.removeItem("companies"); // Clear user from local storage
        },
        setUserSuccess: (state, action) => {
            state.user = action.payload;
            localStorage.setItem("user", JSON.stringify(state.user)); // Save user to local storage
        },
        // update such that any key in the user object can be updated
        updateUserSuccess: (state, action) => {
            state.user = { ...state.user, ...action.payload };
            localStorage.setItem("user", JSON.stringify(state.user)); // Save user to local storage
        },
        setCompaniesSuccess: (state, action) => {
            state.companies = action.payload;
            localStorage.setItem("companies", JSON.stringify(action.payload)); // Save companies to local storage
        },
        setCurrentCompany: (state, action) => {
            state.current_company_id = action.payload;
            localStorage.setItem("current_company_id", action.payload);
        },
        updateLikes: (state, action) => {
            const { funding_id, isLiked } = action.payload;
            console.log(action.payload);
            // Ensure the liked_fundings array exists
            if (!state.user.liked_fundings) {
                state.user.liked_fundings = [];
            }
            // Add or remove the fundingId based on isLiked
            if (isLiked) {
                // Add the fundingId if it does not already exist
                if (!state.user.liked_fundings.includes(funding_id)) {
                    state.user.liked_fundings.push(funding_id);
                }
            } else {
                // Remove the fundingId if it exists
                state.user.liked_fundings = state.user.liked_fundings.filter(id => id !== funding_id);
            }
            // Optionally, update the local storage with the new user state
            localStorage.setItem("user", JSON.stringify(state.user));
        },
        setFilters: (state, action) => {
            state.filters = { ...state.filters, ...action.payload };
            // Optionally save filters to localStorage
            localStorage.setItem('searchFilters', JSON.stringify(state.filters));
        },
        initializeFilters: (state) => {
            // Load filters from localStorage if they exist
            const savedFilters = localStorage.getItem('searchFilters');
            if (savedFilters) {
                state.filters = JSON.parse(savedFilters);
            }
        }
    },
});

export const { loginSuccess, logout, registerSuccess, setUserSuccess, updateUserSuccess, setCompaniesSuccess, setCurrentCompany, loadUserFromStorage, updateLikes, setFilters, initializeFilters } = authReducer.actions;

export const registerUser = (userData) => async (dispatch) => {
    try {
        const response = await axiosInstance.post('/auth/register', userData);

        // Dispatch a success action or handle the response as needed
        dispatch(registerSuccess(response.data));
        console.log("User registered successfully with data: ", response.data);

        return response.data;
    } catch (error) {
        console.log(error);
        return Promise.reject(error); //.response.data.detail
    }
};


export const setCompanies = (userData) => async (dispatch) => {
    axiosInstance.get("/companies/getAll", { withCredentials: true })
        .then((res) => {
            console.log("Fetched companies", res.data);
            dispatch(setCompaniesSuccess(res.data));
        })
        .catch((error) => {
            console.log("Could not fetch companies");
            console.log(error);
            return Promise.reject(error.response.data.detail);
        });
};

export const loginUser = (userData) => async (dispatch) => {
    try {
        const formData = new FormData();
        formData.set('username', userData.email);
        formData.set('password', userData.password);

        const response = await axiosInstance.post(
            '/auth/login',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
            { withCredentials: true }
        );
        // Dispatch a success action or handle the response as needed
        dispatch(loginSuccess(response.data));
        console.log("User logged in successfully with data: ", response.data);

        // save token in local storage (to be used as bearer token in future requests)
        setSession(response.data.access_token);

        return response; //.data
    } catch (error) {
        return Promise.reject(error); //.response.data.detail
    }
};

export const getUser = () => async (dispatch) => {
    if (isSessionSet()) {
        console.log("Fetching user");
        axiosInstance.get("/users/me", { withCredentials: true })
            .then((res) => {
                dispatch(setUser(res.data));
                if (res.data.is_verified === true) {
                    console.log("User is verified");
                    return true;
                } else {
                    console.log("User is not verified");
                    return false;
                }
            })
            .catch((err) => {
                console.log("Could not fetch user");
                console.log(err);
            });
    }
};

export const resetPassword = (userData) => async (dispatch) => {
    try {
        const response = await axiosInstance.post('/auth/forgot-password', { email: userData.email });
        console.log("Password reset mail sent: ", response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error.response.data.detail);
    }
};

export const updateUser = (userData) => async (dispatch) => {
    try {
        dispatch(updateUserSuccess(userData));
        console.log("User updated successfully with data: ", userData);
    }
    catch (error) {
        return Promise.reject(error.response.data.detail);
    }
}

export const setUser = (userData) => async (dispatch) => {
    try {
        dispatch(setUserSuccess(userData));
        console.log("User set successfully with data: ", userData);
    }
    catch (error) {
        return Promise.reject(error.response.data.detail);
    }
};

export const login2 = (email, password) => async (dispatch) => {
    // try {
    //     const response = await axiosInstance.post('/auth/login', { email, password });
    //     setSession(response.data.accessToken);
    //     const userResponse = await axiosInstance.get('/users/me');
    //     dispatch(loginSuccess(userResponse.data));
    // } catch (error) {
    //     console.error(error);
    //     // Handle error
    //     const toast = useToast();
    //     toast({
    //         title: `${error.response.data.detail}`,
    //         status: "error",
    //         isCloseable: true,
    //         duration: 1500,
    //     });
    // }

    console.log('in login function');
    const formData = new FormData();
    formData.set('username', email);
    formData.set('password', password);
    axiosInstance.post(
        'users/auth/cookie/login',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    )
        .then((response) => {
            console.log(response);
            const userResponse = axiosInstance.get('/users/me');
            dispatch(loginSuccess(userResponse.data));
        })
        .catch((error) => {
            console.log("Could not login");
            const toast = useToast();
            toast({
                title: `${error.response.data.detail}`,
                status: "error",
                isCloseable: true,
                duration: 1500,
            });
        });
};

export const performLogout = () => (dispatch) => {
    resetSession();
    dispatch(logout());
};

export default authReducer.reducer;
