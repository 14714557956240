import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import styles from "./Main.module.css";
import feed from "./Feed.module.css"; // Import the CSS file
import Left from "./Left";
import LikedFundingScroll from "./LikedFundingScroll";
import Header from "./Header";

function LikesPage(user) {
  //   const user = useSelector((state) => state.auth.user);
  //   console.log("LikesPage: ", user);

  return (
    <div className={feed.container}>
      <Header />
      {/* {!user && <Navigate to="/" />} */}
      <div className={feed.content}>
        <div className={feed.layout}>
          <div className={`${feed.fixed}`}>
            <Left />
          </div>
          <div className={styles.container}>
            <LikedFundingScroll user={user} />
          </div>
          {/* <Right /> */}
        </div>
      </div>
    </div>
  );
}

export default LikesPage;
