import React, { useState } from "react";
import {
  FaBars,
  FaTimes,
  FaEuroSign,
  FaPiggyBank,
  FaHandHoldingUsd,
} from "react-icons/fa"; // Import icons
import "./NavBar.css"; // Import the CSS file
import { Link } from "react-router-dom";

const NavBar = function (props) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <nav className="nav">
      <Link to="/" className="logo-link">
        <img
          className="logo-finaccess"
          src="/images/FinaccessLogoNoBG.png"
          alt="Logo"
        />
      </Link>

      <button
        className="burger"
        onClick={() => setIsNavExpanded(!isNavExpanded)}
      >
        {isNavExpanded ? <FaTimes /> : <FaBars />}
      </button>

      <div className={`menu ${isNavExpanded ? "expanded" : ""}`}>
        <a className="link-page" href="/finantari-neramburasible">
          <FaEuroSign className="icon" />
          <span className="link-text">European Funds</span>
        </a>
        <a className="link-page" href="/finantari-ramburasible">
          <FaPiggyBank className="icon" />
          <span className="link-text">Bank Financing</span>
        </a>
        <a className="link-page" href="/credite">
          <FaHandHoldingUsd className="icon" />
          <span className="link-text">Alternative Financing</span>
        </a>
      </div>

      <div className="log-buttons">
        <a className="sign-in" href="signin">
          Sign In
        </a>
        <a className="join" href="/signup">
          Join Now
        </a>
      </div>
    </nav>
  );
};

export default NavBar;
