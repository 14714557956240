import React from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import MiniCard from "./FinanceOptionMini";
import euroFlag from "./assets/european_flag2.png";
import ingLogo from "./assets/ing.png";
import btLeasingLogo from "./assets/btleasing.png";
import bcrLogo from "./assets/bcr.png";
import spotawheelLogo from "./assets/spotawheel.png";
import faguraLogo from "./assets/fagura.png";
import roninLogo from "./assets/ronin.png";

import "react-horizontal-scrolling-menu/dist/styles.css";
import { px } from "framer-motion";

const getItems = () =>
  Array(20)
    .fill(0)
    .map((_, ind) => ({ id: `element-${ind}` }));

const getItems2 = () => [
  {
    title: "Sprijin pentru întreprinderi nou înființate inovatoare",
    description: "Până la 1,6 milioane de euro, cu 0% cofinanțare",
    image: euroFlag,
    type: "Fond European",
    amount: "până la €1,6 mil",
    likes: 7,
    comments: 20,
  },

  {
    title: "Femeia Antreprenor",
    description: "Granturi în valoare maximă de 200.000 lei/IMM",
    image: euroFlag,
    type: "Fond European",
    amount: "200.000 lei",
    likes: 7,
    comments: 20,
  },
  {
    title: "Credit de Investitii",
    description: "Până la 2.500.000 lei credit de investiții",
    image: ingLogo,
    type: "Credit",
    amount: "până la 2.500.000 lei",
    likes: 7,
    comments: 20,
  },
  {
    title: "Leasing auto electrice sau hybrid",
    description: "Pentru compania ta dobândă în EUR: marja 3,5% + EURIBOR6M",
    image: btLeasingLogo,
    type: "Leasing",
    amount: "",
    likes: 7,
    comments: 20,
  },
  {
    title: "Electric-up 2",
    description:
      "150.000 euro pentru instalare panouri fotovoltaice și stații de încărcare",
    image: euroFlag,
    type: "Fond European",
    amount: "150.000 euro",
    likes: 7,
    comments: 20,
  },
  {
    title: "Credite pentru finanțarea și achiziția culturilor agricole",
    description: "",
    image: bcrLogo,
    type: "Credit",
    amount: "",
    likes: 7,
    comments: 20,
  },
  {
    title: "Leasing cu 0% avans",
    description: "Spotawheel GO, cel mai flexibil leasing cu 0% avans",
    image: spotawheelLogo,
    type: "Leasing",
    amount: "0% avans",
    likes: 7,
    comments: 20,
  },
  {
    title: "Credite online pentru afaceri",
    description: "Obții finanțare direct de la investitori",
    image: faguraLogo,
    type: "Credit",
    amount: "",
    likes: 7,
    comments: 20,
  },
  {
    title: "Fondul de Modernizare",
    description: "Până la 20 milioane de euro, pentru parcuri fotovoltaice",
    image: euroFlag,
    type: "Fond European",
    amount: "până la €20 mil",
    likes: 7,
    comments: 20,
  },
  {
    title: "Finanțare participativă",
    description: "Pentru Start Up-ul tău",
    image: roninLogo,
    type: "Finanțare participativă",
    amount: "",
    likes: 7,
    comments: 20,
  },
];

function HorizontalCarousel() {
  const [items] = React.useState(getItems2);
  const [selected, setSelected] = React.useState([]);

  const isItemSelected = (id) => !!selected.find((el) => el === id);

  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);

      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== id)
          : currentSelected.concat(id)
      );
    };

  return (
    <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
      {items.map(
        ({ title, description, image, type, amount, likes, comments }) => (
          <MiniCard
            title={title}
            description={description}
            image={image}
            type={type}
            amount={amount}
            likes={likes}
            comments={comments}
          />
        )
      )}
    </ScrollMenu>
  );
}

const LeftArrow = () => {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <Arrow
      disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
      className="left"
    >
      &laquo; {/* Left arrow */}
    </Arrow>
  );
};

const RightArrow = () => {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <Arrow
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
      className="right"
    >
      &raquo;
    </Arrow>
  );
};

function Arrow({ children, disabled, onClick, className }) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`arrow ${className}`}
      // style={{
      //   weight: 600,
      //   padding: "10px",
      //   fontSize: "xxx-large",
      // }}
      style={{
        weight: 600,
        padding: "10px",
        fontSize: "xxx-large",
        cursor: "pointer",
        opacity: disabled ? "0.5" : "1",
      }}
    >
      {children}
    </button>
  );
}

// function Card({ onClick, selected, title, itemId }) {
//   const visibility = React.useContext(VisibilityContext);
//   const isVisible = visibility.isItemVisible(itemId);

//   return (
//     <div
//       onClick={() => onClick(visibility)}
//       style={{
//         width: "160px",
//         border: "1px solid",
//         margin: "0 10px",
//         borderRadius: "8px",
//         cursor: "pointer",
//         overflow: "hidden",
//         userSelect: "none",
//       }}
//       tabIndex={0}
//     >
//       <div className="card" style={{ padding: "10px" }}>
//         <div>{title}</div>
//         <div>visible: {JSON.stringify(isVisible)}</div>
//         <div>selected: {JSON.stringify(!!selected)}</div>
//       </div>
//       <div
//         style={{
//           height: "100px",
//           backgroundColor: selected ? "green" : "bisque",
//         }}
//       />
//     </div>
//   );
// }

export default HorizontalCarousel;
