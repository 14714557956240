import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./FinanceCard.module.css";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Box,
  useToast,
  Avatar,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FaChevronUp,
  FaChevronDown,
  FaTimesCircle,
  FaCheckCircle,
  FaQuestionCircle,
  FaHandHoldingUsd,
  FaThumbsUp,
  FaLinkedinIn,
  FaTwitter,
  FaFacebook,
  FaComments,
  FaShare,
  FaRegThumbsUp,
  FaStar,
  FaClipboardCheck,
  FaClock,
  FaHourglassEnd,
  FaHandSparkles,
  FaUserTie,
  FaBookmark,
  FaRegBookmark,
  FaLink,
} from "react-icons/fa";
import { BiLike, BiChat, BiShare, BiSend } from "react-icons/bi";
import { TimeIcon, EmailIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { PopupModal } from "react-calendly";
import { useSelector } from "react-redux";
import axiosInstance from "../../services/axios";
import { updateLikes } from "../../reducers/authReducer";
// import ColoredExpandableText from "../Feed/FundingCard/ColoredExpandableText";
import FundingDetails from "./FundingDetails"; // Assume this component exists
import { parseDate } from "../../utils/general";

const processValue = (valoareMin, valoareMax) => {
  // convert to string
  valoareMin = valoareMin || "null";
  valoareMax = valoareMax || "null";
  if (valoareMin.includes("null") && valoareMax.includes("null")) {
    return "Neclar";
  } else if (valoareMin.includes("null")) {
    return `până la ${valoareMax}`;
  } else if (valoareMax.includes("null")) {
    return `de la ${valoareMin}`;
  } else {
    return `${valoareMin} - ${valoareMax}`;
  }
};

const FinanceCard = ({ funding, grid }) => {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [question, setQuestion] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const selectedCompany = user.companies[0];
  const dispatch = useDispatch();
  const toast = useToast();
  const [email, setEmail] = useState(user.email); // Prefilled with user email
  const [name, setName] = useState(user.name); // Prefilled with user name
  const [message, setMessage] = useState(user.name);
  const [isEligibilityVisible, setIsEligibilityVisible] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const copyToClipboard = async () => {
    try {
      const url = `${window.location.origin}/funding/${funding._id}`;
      await navigator.clipboard.writeText(url);
      // Optional: Add a toast notification
      toast({
        title: "Link copied!",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (err) {
      console.error('Failed to copy:', err);
      toast({
        title: "Failed to copy link",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const toggleEligibilityVisibility = () => {
    setIsEligibilityVisible(!isEligibilityVisible);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  useEffect(() => {
    if (user.liked_fundings.includes(funding.funding_id)) {
      setIsLiked(true);
    }
  }, [funding.funding_id, user]);

  const toggleLike = () => {
    setIsLiked(!isLiked);
    // console.log(funding);
    let params = {
      fundingId: funding.funding_id,
      isLiked: !isLiked,
    };
    axiosInstance
      .get(`/users/like/`, { params })
      .then((response) => {
        // console.log("Likes: ", response);
        dispatch(
          updateLikes({ funding_id: funding.funding_id, isLiked: !isLiked })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEligibilityColor = (eligibility) => {
    // if false in the list, return red else green
    if (eligibility.includes(false)) {
      return "red";
    } else {
      return "green";
    }
  };

  const shareOnSocialMedia = (platform) => {
    // Construct the URL for sharing
    const url = `${window.location.origin}/funding/${funding._id}`; //encodeURIComponent()
    let shareUrl = "";

    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=Check out this great opportunity: ${encodeURIComponent(
          funding.title
        )}`;
        break;
      case "linkedin":
        // shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${encodeURIComponent(
        //   funding.title
        // )}`;
        shareUrl = `https://www.linkedin.com/feed/?linkOrigin=LI_BADGE&shareActive=true&shareUrl=${url}&text=${encodeURIComponent(
          funding.title
        )}`;
        break;
      case "email":
        const subject = encodeURIComponent(`Check this out: ${funding.title}`);
        const body = encodeURIComponent(`Take a look at this: ${url}`);
        window.open(`mailto:?subject=${subject}&body=${body}`, "_blank");
        break;
      default:
        console.log("error");
    }

    if (shareUrl) {
      window.open(shareUrl, "_blank");
    }
  };

  const getCircleColor = (score) => {
    if (score >= 75) {
      return "green";
    } else if (score >= 50) {
      return "#f1c40f"; // Yellow for medium scores
    } else {
      return "#e74c3c"; // Red for low scores
    }
  };

  const calendlyUrl = "https://calendly.com/cosmin-n-moisii/discutie-initiala";

  const handleQuestionSubmit = async () => {
    setIsSubmitting(true);
    const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    };

    const isInvalid = () => {
      let error = "";
      if (!name || name.trim() === "") {
        error = "Numele este obligatoriu";
        return error;
      }
      if (!email || !validateEmail(email)) {
        error = "Email invalid";
        return error;
      }
      if (!message || message.trim() === "") {
        error = "Intrebarea este obligatorie";
        return error;
      }
      return false;
    };
    let validation = isInvalid();
    // console.log("Validation: ", message);
    if (validation) {
      toast({
        title: "Eroare",
        description: validation,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsSubmitting(false);
      return;
    }

    try {
      await axiosInstance.post("/users/send-question", {
        data: {
          email: email,
          name: name,
          message: message,
          funding: funding.title,
          company: selectedCompany,
        },
      });

      toast({
        title: "Email sent.",
        description: "Intrebarea ta a fost trimisa cu succes!",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      setQuestion("");
      setIsModalOpen(false);
    } catch (error) {
      toast({
        title: "Eroare",
        description: "Am intampinat o eroare la trimiterea mesajului.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const [expandedSuitable, setSuitableExpanded] = useState(false);

  const handleToggleExpandSuitable = () => {
    setSuitableExpanded(!expandedSuitable);
  };

  const handleCardClick = () => {
    if (funding && funding._id) {
      // console.log("Funding: ", funding._id);
      onOpen(); // Open the modal instead of navigating
    } else {
      console.error("Invalid funding or _id");
      // Optionally, you can show an error message to the user here
    }
  };

  const getEligibilityBadge = () => {
    if (!funding.evaluation) {
      return {
        icon: <FaQuestionCircle color="#400000" />,
        text: "Eligibilitate necunoscută",
        color: "#3b3b3b",
        description: "Fă onboarding pentru a verifica potrivirea",
        link: "/onboard2"
      };
    }

    const totalScore = funding.evaluation.eligibility_note.mark + funding.evaluation.scope_note.mark;
    if (totalScore >= 150) {
      return {
        icon: <FaCheckCircle color="green" />,
        text: "Potrivit pentru tine",
        color: "green",
        eligibilityMark: funding.evaluation.eligibility_note.mark,
        scopeMark: funding.evaluation.scope_note.mark
      };
    } else if (totalScore >= 100) {
      return {
        icon: <FaQuestionCircle color="darkorange" />,
        text: "Poate fi potrivit pentru tine",
        color: "darkorange",
        eligibilityMark: funding.evaluation.eligibility_note.mark,
        scopeMark: funding.evaluation.scope_note.mark
      };
    } else {
      return {
        icon: <FaTimesCircle color="#e74c3c" />,
        text: "Mai puțin potrivit pentru tine",
        color: "#e74c3c",
        eligibilityMark: funding.evaluation.eligibility_note.mark,
        scopeMark: funding.evaluation.scope_note.mark
      };
    }
  };

  const eligibilityBadge = getEligibilityBadge();

  const getStatusInfo = () => {
    const currentDate = new Date();
    const startDate = funding.data_start ? parseDate(funding.data_start) : null;
    const endDate = funding.termen_depunere ? parseDate(funding.termen_depunere) : null;

    if (!startDate && !endDate) {
      return { text: "Urmează", color: "#f39c12", icon: FaClock };
    } else if (startDate && currentDate < startDate) {
      return { text: "Urmează", color: "#f39c12", icon: FaClock };
    } else if ((!startDate || currentDate >= startDate) && (!endDate || currentDate <= endDate)) {
      return { text: "Activ", color: "#2ecc71", icon: FaCheckCircle };
    } else {
      return { text: "Închis", color: "#e74c3c", icon: FaHourglassEnd };
    }
  };

  const statusInfo = getStatusInfo();

  return (
    <div className={styles.cardContainer}>
      <div className={`${styles.header} ${styles.clickable}`} onClick={handleCardClick}>
        <div className={styles.headerContent}>
          <Avatar size="md" src="/images/european_flag.png" />
          <div>
            <div className={styles.title}>{funding.title}</div>
            {/* <div className={styles.subhead}>{funding.descriere_scurta}</div> */}
          </div>
        </div>
      </div>
      <div className={styles.body} onClick={handleCardClick}>
        <div className={styles.badge}>
          <div className={styles.visibleBadge}>
            {eligibilityBadge.icon}
            <div className={styles.badgeContent}>
              {!funding.evaluation ? (
                <a href="/onboard2" className={styles.onboardingLink}>
                  Incepe onboarding-ul pentru a verifica potrivirea
                </a>
              ) : (
                <>
                  <span className={styles.badgeText} style={{ color: eligibilityBadge.color }}>
                    {eligibilityBadge.text}
                  </span>
                  <div className={styles.scoreContainer}>
                    <span className={styles.score}>Eligibilitate: {eligibilityBadge.eligibilityMark}/100</span>
                    <span className={styles.score}>Scop: {eligibilityBadge.scopeMark}/100</span>
                  </div>
                  {/* <a href={`/funding/${funding._id}`} className={styles.moreDetails}>
                    Mai multe detalii
                  </a> */}
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.status}>
          <div className={styles.details}>
            <div className={styles.statusChip} style={{ backgroundColor: statusInfo.color }}>
              <statusInfo.icon color="white" />
              <span style={{ color: "white", marginLeft: "5px" }}>{statusInfo.text}</span>
            </div>
            <div className={styles.detailItem}>
              <span className={styles.detailItemTitle}>Start:</span>
              <span>{funding.data_start}</span>
            </div>
            <div className={styles.detailItem}>
              <span className={styles.detailItemTitle}>Deadline:</span>
              <span>{funding.termen_depunere}</span>
            </div>
            <div className={styles.detailItem}>
              <span className={styles.detailItemTitle}>Valoare:</span>
              <span>{processValue(funding.valoare_min, funding.valoare_max)}</span>
            </div>
            <div className={styles.detailItem}>
              <span className={styles.detailItemTitle}>Cofinanțare:</span>
              <span>{funding.valoare_cofinantare || funding.cofinantare_general}</span>
            </div>
            <div className={styles.detailItem}>
              <span className={styles.detailItemTitle}>Locatie:</span>
              <span>{funding.locatii_eligibile}</span>
            </div>
            <div className={styles.detailItem}>
              <span className={styles.detailItemTitle}>Tip:</span>
              <span>
                {funding.funding_type === "nonrefundable"
                  ? "Nerambursabil"
                  : "Nespecificat"}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.description}>{funding.descriere_scurta}</div>
        <a
          // href="#"
          className={styles.eligibilityLink}
          // onClick={toggleEligibilityVisibility}
          onClick={() => handleCardClick}
        >
          Vezi detalii despre cat de bine se potriveste pentru tine{" "}  {/* Vezi condițiile de eligibilitate */}
        </a>
        <div
          className={`${styles.expandableEligibilitySection} ${
            isEligibilityVisible ? styles.expand : ""
          }`}
        >
        </div>

        <div className={styles.keywords}>
          <span>Keywords:</span>
          <div className={styles.keywordChips}>
            {funding.cuvinte_cheie.map((keyword, index) => (
              <span key={index} className={styles.chip}>
                {keyword}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={`${styles.footerActions} ${grid ? styles.footerGrid : ""}`}>
          <div className={styles.smallActions}>
          <Button
            // style={{padding: "6px"}}
            className={`${styles.actionButton} ${styles.likeButton} ${isLiked ? styles.liked : ''}`}
            onClick={toggleLike}
            // leftIcon={isLiked ? <FaThumbsUp /> : <FaRegThumbsUp />}
            leftIcon={isLiked ? <FaBookmark /> : <FaRegBookmark />}
          >
            {!isMobile && (isLiked ? 'Saved' : 'Save')}
          </Button>
          
          <Menu>
            <MenuButton
              // style={{padding: "6px"}}
              as={Button}
              className={`${styles.actionButton} ${styles.shareButton}`}
              leftIcon={<FaShare />}
            >
              {!isMobile && 'Share'}
            </MenuButton>
            <MenuList>
              <MenuItem icon={<Icon as={FaLink} />} onClick={copyToClipboard}>
                Copy Link
              </MenuItem>
              <MenuItem icon={<FaFacebook />} onClick={() => shareOnSocialMedia("facebook")}>
                Share on Facebook
              </MenuItem>
              <MenuItem icon={<FaLinkedinIn />} onClick={() => shareOnSocialMedia("linkedin")}>
                Share on LinkedIn
              </MenuItem>
              <MenuItem icon={<FaTwitter />} onClick={() => shareOnSocialMedia("twitter")}>
                Share on Twitter
              </MenuItem>
              <MenuItem icon={<EmailIcon />} onClick={() => shareOnSocialMedia("email")}>
                Share via Email
              </MenuItem>
            </MenuList>
          </Menu>
          </div>
          
          {/* <Button
            className={`${styles.actionButton} ${styles.aiButton}`}
            onClick={() => setIsModalOpen(true)}
            leftIcon={<img alt="sparkles" src="/images/sparkles_16.png" />}
            colorScheme="purple"
            variant="outline"
            _hover={{ bg: 'purple.50' }}
          >
            {isMobile ? 'Expert AI' : 'Intreaba un expert AI'}
          </Button> */}
          
          <Button
            className={`${styles.actionButton} ${styles.consultantButton}`}
            onClick={togglePopup}
            leftIcon={<FaUserTie />}
            colorScheme="blue"
            variant="outline"
            _hover={{ bg: 'blue.50' }}
          >
              {/* {isMobile ? 'Consultant' : 'Intreaba un consultant'} */}
              {isMobile ? 'Intreaba un consultant' : 'Programează o consultație gratuită'}

          </Button>
        </div>
      </div>
      <PopupModal
        url={calendlyUrl}
        prefill={{
          email: user.email,
          customAnswers: {
            a1: `I would like to learn more about the ${funding.title} for company ${selectedCompany}`,
          },
        }}
        utm={{
          utmCampaign: user.id,
          utmSource: "company",
          utmMedium: "program",
        }}
        onModalClose={togglePopup}
        open={isPopupOpen}
        rootElement={document.getElementById("root")}
      />
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Trimite o intrebare</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired isInvalid={!name || name.length === 0}>
              <FormLabel>Nume</FormLabel>
              <Input
                value={name || ""}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter your name"
              />
            </FormControl>

            <FormControl
              isRequired
              mt={4}
              isInvalid={!email || email.length === 0}
            >
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={email || ""}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
            </FormControl>

            <FormControl isRequired mt={4}>
              <FormLabel>Numele finantarii</FormLabel>
              <Input value={funding.title} isReadOnly variant="filled" />
            </FormControl>

            <FormControl
              isRequired
              mt={4}
              isInvalid={!message || message.length === 0}
            >
              <FormLabel>Intrebare</FormLabel>
              <Textarea
                value={message || ""}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Enter your question here..."
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="purple"
              mr={3}
              onClick={handleQuestionSubmit}
              isLoading={isSubmitting}
              leftIcon={<BiSend />}
            >
              Send
            </Button>
            <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
      {/* Add this at the end of the component's return statement */}
      <Modal isOpen={isOpen} onClose={onClose} size="l">
        <ModalOverlay />
          <ModalContent maxWidth="1100px" maxHeight="90vh">
          <ModalHeader>{funding.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto">
            {/* <Box maxWidth="100%" maxHeight="calc(90vh - 120px)"> */}
              <FundingDetails funding={funding} />
            {/* </Box> */}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default FinanceCard;
