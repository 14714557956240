import axios from "axios";

const baseURL = `${process.env.REACT_APP_BASE_URL}`; // PUT INTO .ENV FILE !!!!!!!!!!!!!!!!!!!!!!!!!!!!

const axiosInstance = axios.create({
  baseURL,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

try {
  let accessToken = localStorage.getItem("accessToken");
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
} catch (error) {
  console.log("No access token available.");
}
  

export default axiosInstance;
