import {
  Box,
  Button,
  CheckboxGroup,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Textarea,
  VStack,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Switch,
  HStack,
  Flex,
  Spinner,
  Spacer,
  Collapse,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { GroupBase, OptionBase, Select as MSelect } from "chakra-react-select";
import { Container } from "@chakra-ui/layout";
import { FixedSizeList as List } from "react-window";
import { components } from "react-select";
import React, { useState, useEffect } from "react";

const counties = [
  "Alba",
  "Arad",
  "Arges",
  "Bacau",
  "Bihor",
  "Bistrita-Nasaud",
  "Botosani",
  "Braila",
  "Brasov",
  "Bucuresti",
  "Buzau",
  "Calarasi",
  "Caras-Severin",
  "Cluj",
  "Constanta",
  "Covasna",
  "Dambovita",
  "Dolj",
  "Galati",
  "Giurgiu",
  "Gorj",
  "Harghita",
  "Hunedoara",
  "Ialomita",
  "Iasi",
  "Ilfov",
  "Maramures",
  "Mehedinti",
  "Mures",
  "Neamt",
  "Olt",
  "Prahova",
  "Salaj",
  "Satu Mare",
  "Sibiu",
  "Suceava",
  "Teleorman",
  "Timis",
  "Tulcea",
  "Vaslui",
  "Valcea",
  "Vrancea",
];

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div>
        <strong>{props.data.value}</strong> - {props.data.label}
      </div>
    </components.Option>
  );
};

const MenuList = (props) => {
  const { children, maxHeight } = props;
  const height = 35; // Item height

  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      width="100%"
      style={{ backgroundColor: "white" }} // Background color
    >
      {({ index, style }) => (
        <div style={{ ...style, overflow: "hidden" }}>{children[index]}</div>
      )}
    </List>
  );
};

function CompanySettings(data, updateData) {
  const [isExpandedGenerale, setIsExpandedGenerale] = useState(true);
  const [isExpandedFinanciare, setIsExpandedFinanciare] = useState(false);
  const [isExpandedCereri, setIsExpandedCereri] = useState(false);

  const handleToggleGenerale = () => {
    setIsExpandedGenerale(!isExpandedGenerale);
  };
  const handleToggleFinanciare = () => {
    setIsExpandedFinanciare(!isExpandedFinanciare);
  };
  const handleToggleCereri = () => {
    setIsExpandedCereri(!isExpandedCereri);
  };

  const currentYear = new Date().getFullYear();
  const financialYears = [currentYear - 1, currentYear - 2, currentYear - 3];
  console.log("Step1: ", data);
  const financialIndicators = [
    { label: "Cifra de afaceri", key: "cifra_de_afaceri_neta" },
    { label: "Capitaluri proprii", key: "capitaluri_total" },
    { label: "Profit net", key: "profit_net" },
    { label: "Active circulante", key: "active_circulante_total" },
    { label: "Active imobilizate", key: "active_imobilizate_total" },
    { label: "Datorii totale", key: "datorii_total" },
    { label: "Venituri totale", key: "venituri_totale" },
    { label: "Cheltuieli totale", key: "cheltuieli_totale" },
    { label: "Profit brut", key: "profit_brut" },
    { label: "Pierdere brută", key: "pierdere_bruta" },
    { label: "Casă și conturi", key: "casa_si_conturi" },
    { label: "Stocuri", key: "stocuri" },
    { label: "Creanțe", key: "creante" },
    { label: "Provizioane", key: "provizioane" },
    { label: "Venituri în avans", key: "venituri_in_avans" },
    { label: "Cheltuieli în avans", key: "cheltuieli_in_avans" },
    { label: "Numar mediu angajati", key: "numar_mediu_de_salariati" },
  ];

  return (
    <Container
      borderRadius={10}
      // marginTop={15}
      // padding={5}
      maxW="100%"
      margin={0}
      backgroundColor="white"
    >
      <Box
        onClick={handleToggleGenerale}
        cursor="pointer"
        display="flex"
        alignItems="center"
        padding={5}
        _hover={{
          "& > svg": {
            transform: isExpandedGenerale ? "rotate(225deg)" : "rotate(45deg)",
          },
          backgroundColor: "gray.100",
        }}
      >
        <Heading fontSize="xl">Informatii generale</Heading>
        <Spacer />
        <ChevronDownIcon
          ml={2}
          transition="transform 0.2s"
          transform={isExpandedGenerale ? "rotate(180deg)" : "rotate(0deg)"}
        />
      </Box>
      <Collapse in={isExpandedGenerale} animateOpacity>
        <VStack spacing={5} padding={5}>
          <HStack w="full" spacing={5}>
            <FormControl
              id="companyName"
              isRequired
              isInvalid={!data.companyName || data.companyName === ""}
            >
              <FormLabel>Numele companiei</FormLabel>
              <Input
                placeholder="COMPANY NAME S.R.L."
                value={data.companyName}
                onChange={(e) =>
                  updateData({ ...data, companyName: e.target.value })
                }
              />
            </FormControl>
            <FormControl
              id="companyForm"
              isRequired
              isInvalid={!data.companyForm || data.companyForm === ""}
            >
              <FormLabel>Forma juridica</FormLabel>
              <Select
                placeholder="Selectează forma juridică"
                value={data.companyForm || ""}
                onChange={(e) =>
                  updateData({ ...data, companyForm: e.target.value })
                }
              >
                <option value="S.R.L.">S.R.L.</option>
                <option value="S.A.">S.A.</option>
                <option value="P.F.A.">P.F.A.</option>
                <option value="I.I.">I.I.</option>
                <option value="I.F.">I.F.</option>
              </Select>
            </FormControl>
          </HStack>
          <HStack w="full" spacing={5}>
            <FormControl
              id="registrationDate"
              isRequired
              isInvalid={!data.registrationDate || data.registrationDate === ""}
            >
              <FormLabel>Data înregistrării</FormLabel>
              <Input
                type="date"
                value={data.registrationDate}
                onChange={(e) =>
                  updateData({ ...data, registrationDate: e.target.value })
                }
              />
            </FormControl>
            <FormControl id="registrationNumber" isRequired>
              <FormLabel>Numarul inregistrarii</FormLabel>
              <Input
                type="text"
                value={data.registrationNumber}
                onChange={(e) =>
                  updateData({ ...data, registrationNumber: e.target.value })
                }
              />
            </FormControl>
            <FormControl id="companyCategory" isRequired>
              <FormLabel>Categoria entității</FormLabel>
              <Select
                placeholder="Selectează categoria"
                value={data.companyCategory || ""}
                onChange={(e) =>
                  updateData({ ...data, companyCategory: e.target.value })
                }
              >
                <option value="Micro">Micro</option>
                <option value="Small">Mică</option>
                <option value="Medium">Medie</option>
                <option value="Big">Mare</option>
              </Select>
            </FormControl>
          </HStack>
          <FormControl id="companyAddress" isRequired>
            <FormLabel>Adresa sediului social</FormLabel>
            <Input
              placeholder="Oraș, Strada FINACCESS 3, et.1"
              value={data.companyAddress || ""}
              onChange={(e) =>
                updateData({ ...data, companyAddress: e.target.value })
              }
            />
          </FormControl>
          <FormControl
            id="companyRegions"
            isInvalid={!data.companyRegions || data.companyRegions === ""}
          >
            <FormLabel>Locatiile tuturor sediilor</FormLabel>
            <MSelect
              isMulti
              name="secondaryAddresses"
              options={counties.map((county) => ({
                value: county,
                label: county,
              }))}
              placeholder="Selectează județele"
              closeMenuOnSelect={false}
              onChange={(selectedOptions) => {
                const selectedCounties = selectedOptions
                  ? selectedOptions.map((option) => option.value)
                  : [];
                updateData({
                  ...data,
                  companyRegions: selectedCounties,
                });
              }}
            />
          </FormControl>
          <FormControl
            id="authorizedCaen"
            isInvalid={
              !data.authorizedCaenCodes || data.authorizedCaenCodes === ""
            }
          >
            <FormLabel>Codurile CAEN autorizate</FormLabel>
            <MSelect
              components={{ MenuList, Option }}
              isMulti
              name="authorizedCaen"
              placeholder="Selectează codurile CAEN"
              closeMenuOnSelect={false}
              onChange={(selectedOptions) => {
                const selectedCodes = selectedOptions
                  ? selectedOptions.map((option) => option.value)
                  : [];
                updateData({
                  ...data,
                  authorizedCaenCodes: selectedCodes,
                });
              }}
            />
          </FormControl>
          <FormControl
            id="companyDescription"
            isRequired
            isInvalid={
              !data.companyDescription || data.companyDescription === ""
            }
          >
            <FormLabel>Scurta descriere</FormLabel>
            <Textarea
              value={data.companyDescription}
              placeholder="Suntem un producator de mobila, care fabrica mese si scaune pt birou."
              onChange={(e) =>
                updateData({ ...data, companyDescription: e.target.value })
              }
            />
          </FormControl>
          <FormControl display="flex" alignItems="center" id="TVA">
            <FormLabel htmlFor="tva-switch" mb="0">
              Plătitor de TVA
            </FormLabel>
            <Switch
              id="tva-switch"
              isChecked={data.tva === "da"}
              onChange={(e) =>
                updateData({ ...data, tva: e.target.checked ? "da" : "nu" })
              }
              colorScheme="green"
            />
          </FormControl>
        </VStack>
      </Collapse>

      <Box
        onClick={handleToggleFinanciare}
        cursor="pointer"
        display="flex"
        alignItems="center"
        padding={5}
        _hover={{
          "& > svg": {
            transform: isExpandedFinanciare
              ? "rotate(225deg)"
              : "rotate(45deg)",
          },
          backgroundColor: "gray.100",
        }}
      >
        <Heading fontSize="xl">Indicatori Financiari</Heading>
        <Spacer />
        <ChevronDownIcon
          ml={2}
          transition="transform 0.2s"
          transform={isExpandedFinanciare ? "rotate(180deg)" : "rotate(0deg)"}
        />
      </Box>
      <Collapse in={isExpandedFinanciare} animateOpacity>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Indicatori</Th>
                {financialYears.map((year) => (
                  <Th key={year}>{year}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {financialIndicators.map((indicator) => (
                <Tr key={indicator.key}>
                  <Td>{indicator.label}</Td>
                  {financialYears.map((year, index) => (
                    <Td key={year}>
                      <Input
                        type="number"
                        value={
                          data[`financialYear${index + 1}`]?.[indicator.key] !==
                            null &&
                          data[`financialYear${index + 1}`]?.[indicator.key] !==
                            undefined
                            ? data[`financialYear${index + 1}`]?.[indicator.key]
                            : ""
                        }
                        onChange={(e) =>
                          updateData({
                            ...data,
                            [`financialYear${index + 1}`]: {
                              ...data[`financialYear${index + 1}`],
                              [indicator.key]: e.target.value,
                            },
                          })
                        }
                      />
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Collapse>

      <Box
        onClick={handleToggleCereri}
        cursor="pointer"
        display="flex"
        alignItems="center"
        padding={5}
        _hover={{
          "& > svg": {
            transform: isExpandedCereri ? "rotate(225deg)" : "rotate(45deg)",
          },
          backgroundColor: "gray.100",
        }}
      >
        <Heading fontSize="xl">Cereri finantare</Heading>
        <Spacer />
        <ChevronDownIcon
          ml={2}
          transition="transform 0.2s"
          transform={isExpandedCereri ? "rotate(180deg)" : "rotate(0deg)"}
        />
      </Box>
      <Collapse in={isExpandedCereri} animateOpacity>
        <VStack spacing={5} padding={5}>
          <FormControl
            id="purpose"
            isRequired
            isInvalid={!data.purpose || data.purpose === ""}
          >
            <FormLabel>Cu ce scop vei folosi banii de finanțare?</FormLabel>
            <Textarea
              placeholder="Vreau să extind suprafața cultivată a fermei pentru a produce mai multe legume."
              value={data.purpose || ""}
              onChange={(e) => updateData({ ...data, purpose: e.target.value })}
            />
          </FormControl>

          <FormControl
            id="purchases"
            isRequired
            isInvalid={!data.purchases || data.purchases === ""}
          >
            <FormLabel>Ce achiziții intenționezi să faci?</FormLabel>
            <Textarea
              placeholder="Vreau să achiziționez tractoare dotate cu tehnologie GPS pentru precizie crescută în lucrările agricole."
              value={data.purchases || ""}
              onChange={(e) =>
                updateData({ ...data, purchases: e.target.value })
              }
            />
          </FormControl>

          <FormControl
            id="amount"
            isRequired
            isInvalid={!data.amount || data.amount === ""}
          >
            <FormLabel>
              Ce sumă ar fi potrivită pentru a îndeplini obiectivele?
            </FormLabel>
            <Select
              placeholder="Selectează intervalul"
              value={data.amount || ""}
              onChange={(e) => updateData({ ...data, amount: e.target.value })}
              defaultValue="50000-250000€"
            >
              <option value="0-10000€">0-10.000 €</option>
              <option value="10000-50000€">10.000-50.000 €</option>
              <option value="50000-250000€">50.000-250.000 €</option>
              <option value="250000-2000000€">250.000-2.000.000 €</option>
              <option value="over 2000000€">peste 2.000.000 €</option>
            </Select>
          </FormControl>

          <FormControl id="financingTypes" isRequired>
            <FormLabel>Cu ce tipuri de finanțări ești de acord?</FormLabel>
            <CheckboxGroup
              colorScheme="green"
              defaultValue={["grant", "loan"]}
              value={data.financingTypes || ["grant", "loan"]}
              onChange={(value) =>
                updateData({ ...data, financingTypes: value })
              }
            >
              <Stack spacing={2}>
                <Checkbox value="grant">Fonduri naționale/europene</Checkbox>
                <Checkbox value="loan">Credite bancare</Checkbox>
                <Checkbox value="equity" isDisabled>
                  Finanțare de tip equity (disponibil în curând)
                </Checkbox>
                <Checkbox value="crowdfunding" isDisabled>
                  Finanțare de tip crowdfunding (disponibil în curând)
                </Checkbox>
                <Checkbox value="grant-uri" isDisabled>
                  Grant-uri (disponibil în curând)
                </Checkbox>
              </Stack>
            </CheckboxGroup>
          </FormControl>
        </VStack>
      </Collapse>
    </Container>
  );
}

export default CompanySettings;
