// MapComponent.js
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const MapComponent = ({ data }) => {
  // Default position set to Bucharest, Romania
  const position = [44.4268, 26.1025];

  return (
    <MapContainer center={position} zoom={6} style={{ height: '400px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {data.map((item, index) => (
        // Placeholder position; you should use actual coordinates
        <Marker key={index} position={position}>
          <Popup>{item['City/Location']}</Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MapComponent;
