import jwt_decode from "jwt-decode";


import { loginUser, setUser } from "../reducers/authReducer";

export const validateToken = (token) => {
  const now = Math.round(new Date().getTime() / 1000);
  const decodedToken = jwt_decode(token);
  const isValid = decodedToken && now < decodedToken.exp;

  return isValid;
};

export const onSubmitLogin = async (dispatch, navigate, toast, values) => {
  try {
    const response = await dispatch(loginUser(values));
    console.log("_----------------------------------------");
    console.log(response);
    if (response && response.access_token) {
      // Show success toast
      toast({
        title: "Signed in successfully.",
        status: "success",
        isClosable: true,
        duration: 2500,
      });
      // Navigate to another page
      navigate("/feed", { replace: true });
    } else {
      // Show error toast for login failure
      toast({
        title: "Login failed. Please check your credentials.",
        status: "error",
        isClosable: true,
        duration: 2500,
      });
    }
  } catch (error) {
    // Show error toast for exceptions
    toast({
      title: error.message || "An error occurred during login.",
      status: "error",
      isClosable: true,
      duration: 2500,
    });
  }
};



