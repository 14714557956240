import React, { useState, useEffect } from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../reducers/authReducer";

import styles from "./auth.module.css"; // Importing the custom CSS module

function ForgotPassword() {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timeLeft, setTimeLeft] = useState(120);

  // this is the logic for the resend email button timer
  useEffect(() => {
    let timer;
    if (isButtonDisabled) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isButtonDisabled]);

  useEffect(() => {
    if (timeLeft <= 0) {
      setIsButtonDisabled(false);
      setTimeLeft(120);
    }
  }, [timeLeft]);

  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const onSubmit = async (values) => {
    try {
      await dispatch(resetPassword(values));
      toast({
        title: "Please check your email for a reset link.",
        status: "success",
        isClosable: true,
        duration: 7500,
      });
      setIsButtonDisabled(true);
    } catch (error) {
      toast({
        title: error,
        status: "error",
        isCloseable: true,
        duration: 7500,
      });
    }
  };

  return (
    <Flex height="100vh" alignItems="center" justifyContent="center">
      <Flex
        className={styles.registerContainer}
        direction="column"
        alignItems="center"
        background={useColorModeValue("gray.100", "gray.700")}
        p={12}
        rounded={6}
      >
        <Heading className={styles.registerHeading} mb={6}>
          Forgot your password?
        </Heading>
        <p className={styles.subheader}>
          Please enter the email address you use to sign in to finaccess.ro.
        </p>
        <form className={styles.registerForm} onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={errors.email}>
            <Input
              className={styles.registerInput}
              placeholder="Email"
              background={useColorModeValue("gray.300", "gray.600")}
              type="email"
              size="lg"
              mt={6}
              {...register("email", {
                required: "This is required field",
              })}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <Button
            disabled={isButtonDisabled}
            style={{
              ...styles.button,
              ...(isButtonDisabled ? styles.disabledButton : {}),
            }}
            isLoading={isSubmitting}
            loadingText="Sending email..."
            className={styles.registerButton}
            width="100%"
            type="submit"
          >
            {isButtonDisabled ? `Resend Email in ${formatTime()}` : 'Request reset link'}
          </Button>
        </form>
        <Button
          onClick={() => navigate("/signin", { replace: true })}
          className={styles.alternateButton}
          width="100%"
          mt={6}
        >
          Back to Login
        </Button>
      </Flex>
    </Flex>
  );
}

export default ForgotPassword;
