import { Container } from "@chakra-ui/layout";
import Content from "./Content/Content";
import Sidebar from "./Sidebar/Sidebar";
import Cover from "./Cover";
import Header from "../Feed/Header";
import { useSelector, useDispatch } from "react-redux";

export default function CompanyProfile() {
  const filters = useSelector((state) => state.auth.filters);
  const user = useSelector((state) => state.auth.user);

  return (
    <div>
      <Header 
        // setSearchResults={setSearchResults} 
        user={user}
        filters={filters}
      />
      <Cover />
      <Container display={{ base: "block", md: "flex" }} maxW="container.xl">
        <Sidebar />
        <Content />
      </Container>
    </div>
  );
}
