import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import axiosInstance from "../../services/axios";
import styles from "./FundingDetails.module.css";
import Header from "../Feed/Header";
// import Chat from "./Chat";
import Chat_details from "./Chat_details";
import { FaCheckCircle, FaTimesCircle, FaQuestionCircle } from "react-icons/fa";
import { parseDate } from "../../utils/general";
import { FaClock, FaHourglassEnd } from "react-icons/fa";
import styled from 'styled-components';
import ScrollToTopButton from "./ScrollToTopButton";

const FundingDetails = () => {
  const { id } = useParams();
  const [funding, setFunding] = useState(null);
  const [error, setError] = useState(null);
  const [expandedSections, setExpandedSections] = useState({});
  const sectionRefs = useRef({});
  const [showScrollButton, setShowScrollButton] = useState(false);

  const sections = [
    { id: "descriere", id_short: "descriere_medie", title: "Descriere program" },

    { id: "valori", title: "Valoare finanțare" },
    { id: "cofinantare_general", title: "Cofinanțare" },
    { id: "calendar", title: "Calendarul programului" },

    { id: "achizitii", title: "Achiziții eligibile" },
    // { id: "locatii_general", id_short: "locatii_eligibile", title: "Locații eligibile" },
    { id: "eligibilitate", title: "Criterii de eligibilitate" },
    { id: "neeligibilitate", title: "Criterii de neeligibilitate" },
    { id: "punctaj", title: "Schema de punctaj" },
  ];

  const scrollToSection = (sectionId) => {
    sectionRefs.current[sectionId].scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchFundingDetails = async () => {
      try {
        const response = await axiosInstance.get(`/fundings/details/${id}`);
        setFunding(response.data || null);
      } catch (error) {
        console.error("Error fetching funding details:", error);
        setError("Failed to fetch funding details. Please try again later.");
      }
    };

    id ? fetchFundingDetails() : setError("Invalid funding ID");

    const handleScroll = () => {
      setShowScrollButton(window.pageYOffset > 300);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [id]);

  if (error) return <div className={styles.error}>{error}</div>;
  if (!funding) return <div className={styles.loading}>Loading...</div>;

  const toggleSection = (sectionName) => {
    setExpandedSections(prev => ({
      ...prev,
      [sectionName]: !prev[sectionName]
    }));
  };

  const renderSection = (title, shortContent, longContent, sectionName, hasShortVersion) => {
    const isExpanded = expandedSections[sectionName];
    return (
      <section className={styles.section}>
        <div className={styles.sectionHeader}>
          <h2>{title}</h2>
          {hasShortVersion && (
            <div className={styles.toggleButton} onClick={() => toggleSection(sectionName)}>
              <span>{isExpanded ? "Mai puțin" : "Mai mult"}</span>
              <div className={styles.switch}>
                <div className={`${styles.slider} ${isExpanded ? styles.expanded : ''}`}></div>
              </div>
            </div>
          )}
        </div>
        <div className={`${styles.sectionContent} ${isExpanded || !hasShortVersion ? styles.expanded : ''}`}>
          <ReactMarkdown>{hasShortVersion ? (isExpanded ? longContent : shortContent) : longContent}</ReactMarkdown>
        </div>
      </section>
    );
  };

  const processValue = (valoareMin, valoareMax) => {
    valoareMin = valoareMin || "null";
    valoareMax = valoareMax || "null";
    if (valoareMin.includes("null") && valoareMax.includes("null")) {
      return "Neclar";
    } else if (valoareMin.includes("null")) {
      return `până la ${valoareMax}`;
    } else if (valoareMax.includes("null")) {
      return `de la ${valoareMin}`;
    } else {
      return `${valoareMin} - ${valoareMax}`;
    }
  };

  const getStatusInfo = () => {
    const currentDate = new Date();
    const startDate = funding.data_start ? parseDate(funding.data_start) : null;
    const endDate = funding.termen_depunere ? parseDate(funding.termen_depunere) : null;

    if (!startDate && !endDate) {
      return { text: "Urmează", color: "#f39c12", icon: FaClock };
    } else if (startDate && currentDate < startDate) {
      return { text: "Urmează", color: "#f39c12", icon: FaClock };
    } else if ((!startDate || currentDate >= startDate) && (!endDate || currentDate <= endDate)) {
      return { text: "Activ", color: "#2ecc71", icon: FaCheckCircle };
    } else {
      return { text: "Închis", color: "#e74c3c", icon: FaHourglassEnd };
    }
  };

  const statusInfo = getStatusInfo();

  const renderOverview = () => {
    if (!funding) return null;

    return (
      <div className={styles.overviewSection}>
        <h2>Prezentare generală</h2>
        <div className={styles.overviewGrid}>
          <div className={styles.overviewItem}>
          <div className={styles.statusChip} style={{ backgroundColor: statusInfo.color }}>
              <statusInfo.icon color="white" />
              <span style={{ color: "white", marginLeft: "5px" }}>{statusInfo.text}</span>
            </div>
          </div>
          <div className={styles.overviewItem}>
            <span className={styles.overviewLabel}>Start:</span>
            <span className={styles.overviewValue}>{funding.data_start}</span>
          </div>
          <div className={styles.overviewItem}>
            <span className={styles.overviewLabel}>Deadline:</span>
            <span className={styles.overviewValue}>{funding.termen_depunere}</span>
          </div>
          <div className={styles.overviewItem}>
            <span className={styles.overviewLabel}>Valoare:</span>
            <span className={styles.overviewValue}>{processValue(funding.valoare_min, funding.valoare_max)}</span>
          </div>
          <div className={styles.overviewItem}>
            <span className={styles.overviewLabel}>Cofinanțare:</span>
            <span className={styles.overviewValue}>{funding.valoare_cofinantare || funding.cofinantare_general}</span>
          </div>
          <div className={styles.overviewItem}>
            <span className={styles.overviewLabel}>Locație:</span>
            <span className={styles.overviewValue}>{funding.locatii_eligibile.join(", ")}</span>
          </div>
          <div className={styles.overviewItem}>
            <span className={styles.overviewLabel}>Tip:</span>
            <span className={styles.overviewValue}>
              {funding.funding_type === "nonrefundable" ? "Nerambursabil" : "Nespecificat"}
            </span>
          </div>
        </div>
        <div className={styles.overviewDescription}>
          <p>{funding.descriere_scurta}</p>
        </div>
        <div className={styles.overviewKeywords}>
          <span className={styles.overviewLabel}>Keywords:</span>
          <div className={styles.keywordChips}>
            {funding.cuvinte_cheie.map((keyword, index) => (
              <span key={index} className={styles.chip}>
                {keyword}
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderEvaluationDetails = () => {
    if (!funding || !funding.evaluation) return null;

    const { plus_points, minus_points, questions, eligibility_note, scope_note } = funding.evaluation;

    return (
      <div className={styles.evaluationSection}>
        <h2>Evaluare</h2>
        
        <div className={styles.evaluationPoints}>
          <h3>Puncte Forte</h3>
          <ul className={styles.plusPoints}>
            {plus_points.map((point, index) => (
              <li key={index}>
                <FaCheckCircle className={styles.iconGreen} />
                {point}
              </li>
            ))}
          </ul>

          <h3>Puncte Slabe</h3>
          <ul className={styles.minusPoints}>
            {minus_points.map((point, index) => (
              <li key={index}>
                <FaTimesCircle className={styles.iconRed} />
                {point}
              </li>
            ))}
          </ul>

          <h3>Întrebări</h3>
          <ul className={styles.questions}>
            {questions.map((question, index) => (
              <li key={index}>
                <FaQuestionCircle className={styles.iconBlue} />
                {question}
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.evaluationNotes}>
          <div className={styles.noteBox}>
            <h4>Notă Eligibilitate</h4>
            <div className={styles.noteMark}>{eligibility_note.mark}</div>
            <p>{eligibility_note.commentary}</p>
          </div>
          <div className={styles.noteBox}>
            <h4>Notă Scop</h4>
            <div className={styles.noteMark}>{scope_note.mark}</div>
            <p>{scope_note.commentary}</p>
          </div>
        </div>
      </div>
    );
  };

  const ContentWrapper = styled.div`
  padding-bottom: 0px;
`;
  return (
    <div>
      <ContentWrapper className={styles.hidden}>
      <Header />
      </ContentWrapper>

      <div className={styles.pagePadding}>
        <div className={styles.pageLayout}>
          <div className={styles.tableOfContents}>
            <h2>Cuprins</h2>
            <ul>
              <li><button onClick={() => scrollToSection('overview')}>Prezentare generală</button></li>
              {sections.map((section) => (
                <li key={section.id}>
                  <button onClick={() => scrollToSection(section.id)}>
                    {section.title}
                  </button>
                </li>
              ))}
              <li><button onClick={() => scrollToSection('evaluation')}>Evaluare</button></li>
            </ul>
          </div>
          <div className={styles.detailsContainer}>
            <h1>{funding?.title}</h1>
            <div className={styles.chatSection}>
              <h2>AI Funding Assistant</h2>
              <Chat_details fundingId={funding?._id} />
            </div>
            <div ref={(el) => (sectionRefs.current['overview'] = el)}>
              {renderOverview()}
            </div>
            <div ref={(el) => (sectionRefs.current['evaluation'] = el)}>
              {renderEvaluationDetails()}
            </div>
            {sections.map((section) => (
              <div key={section.id} ref={(el) => (sectionRefs.current[section.id] = el)}>
                {renderSection(
                  section.title,
                  funding?.[section.id_short] || `${section.title}...`,
                  funding?.[section.id],
                  section.id,
                  !!section.id_short // Pass a boolean indicating if there's a short version
                )}
              </div>
            ))}

          </div>
        </div>
      </div>
      {/* <Chat fundingId={id} /> */}
      {showScrollButton && <ScrollToTopButton />}
    </div>
  );
};

export default FundingDetails;
