import React from "react";
import styles from "./Hero.module.css"; // Ensure the CSS module is correctly imported
import HeroImage from "./assets/hero.png";
import SmallHeroImage from "./assets/hero2.2.svg";
import VerticalHeroImage from "./assets/hero_vertical.svg";


import { useNavigate } from "react-router-dom";

const Hero = React.forwardRef((props, ref) => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <section ref={ref} className={styles.heroSection}>
      <div className={styles.heroContent}>
        <div className={styles.heroText}>
          <h1 className={styles.heroTitle}>Bun venit la ctrl.fund!</h1>
          <p className={styles.heroSubtitle}>
            Descoperă finanțări de la{" "}
            <span className={styles.highlight}>Bănci, Fonduri Europene </span>
            <span className={styles.heroSubtitle}>sau</span>{" "}
            <span className={styles.highlight}>Surse alternative.</span>
          </p>
        </div>
        <div className={styles.authButtons}>
          <button
            className={styles.signupBtn}
            onClick={() => handleNavigation("/signup")}
          >
            Sign up
          </button>
          <button
            className={styles.loginBtn}
            onClick={() => handleNavigation("/signin")}
          >
            Log in
          </button>
        </div>
      </div>
      <div className={styles.heroImage}>
        <picture className={styles.heroImg}>
          <source media="(min-width: 768px)" srcSet={HeroImage} />
          <source media="(min-width: 120px)" srcSet={SmallHeroImage} />
          <img src={HeroImage} alt="Hero illustration" />
        </picture>

      </div>
    </section>
  );
});

export default Hero;
